var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { usePlatformUserApi } from '../services/api/platformUserApiHook';
import { useAppDispatch } from '../store/hooks';
import { setLoadingSpinner } from '../store/orgSlice';
const InvitationModal = ({ isOpen, onClose, onOpen, }) => {
    const { verifyUser } = usePlatformUserApi();
    const dispatch = useAppDispatch();
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const queryParams = new URLSearchParams(window.location.search);
        const registrationCode = queryParams.get('key');
        const emailAddress = queryParams.get('email');
        try {
            dispatch(setLoadingSpinner(true));
            if (emailAddress && registrationCode) {
                const verifyObj = {
                    registrationCode: registrationCode,
                    emailAddress: emailAddress,
                    isEulaAccepted: true,
                };
                const response = yield verifyUser(verifyObj);
                if (response) {
                    window.location.href = response;
                }
                dispatch(setLoadingSpinner(false));
            }
            else {
                dispatch(setLoadingSpinner(false));
                handleDecline();
            }
        }
        catch (e) {
            console.log(e);
            dispatch(setLoadingSpinner(false));
            handleDecline();
        }
    });
    const handleDecline = () => {
        onClose();
        onOpen();
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, { closeOnOverlayClick: false, isOpen: isOpen, onClose: onClose, trapFocus: false, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { maxWidth: '473px', p: '40px', borderRadius: '15px', m: 'auto', children: [_jsx(ModalHeader, { p: '0px', mb: '40px', textAlign: 'center', fontFamily: 'Montserrat', fontSize: '32px', fontWeight: 400, letterSpacing: '1.5px', color: '#2E3532', whiteSpace: 'nowrap', children: "You have been invited!" }), _jsx(ModalBody, { p: '0px', mb: '40px', children: _jsxs(Text, { color: '#003B5D', fontSize: '14px', children: ["Please review and accept the", ' ', _jsx(Link, { color: '#003B5D', sx: {
                                            fontStyle: 'italic',
                                            textDecoration: 'underline',
                                        }, target: '_blank', href: 'https://www.sitelogiq.com/mysiteiq-eula/', children: "Terms of Use." }), ' ', _jsx("br", {}), "By accepting the invitation, you are also accepting that you have read and agree with the", ' ', _jsx(Link, { color: '#003B5D', sx: {
                                            fontStyle: 'italic',
                                            textDecoration: 'underline',
                                        }, target: '_blank', href: 'https://www.sitelogiq.com/mysiteiq-eula/', children: "Terms of Use." })] }) }), _jsxs(ModalFooter, { p: '0px', justifyContent: 'space-between', children: [_jsx(Button, { variant: variants.orangeTransparentModalBtn, onClick: handleDecline, fontSize: '14px', fontWeight: '500px', w: '90px', children: "Decline" }), _jsx(Button, { variant: variants.addNewBtn, onClick: onSubmit, fontSize: '14px', fontWeight: '500px', w: '90px', children: "Accept" })] })] })] }) }));
};
export default InvitationModal;
