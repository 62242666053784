import { jsx as _jsx } from "react/jsx-runtime";
import { Image, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { PageLayout } from '../components/layouts/PageLayout';
import landingPage from '../images/landingPage.png';
import { useAppDispatch } from '../store/hooks';
import { toggleOrgsCreateMode } from '../store/orgSlice';
export const LandingPage = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(toggleOrgsCreateMode(false));
    }, []);
    return (_jsx(PageLayout, { children: _jsx(VStack, { justifyContent: 'center', h: '100%', children: _jsx(Image, { maxH: '100%', src: landingPage, alt: 'My Site IQ: Organize; Prioritize; Optimize' }) }) }));
};
