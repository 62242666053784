import { extendTheme } from '@chakra-ui/react';
import { appColors, appValues } from './constants';
export const variants = {
    gradientBtn: 'gradient-btn',
    blueTransparentBtn: 'blue-transparent-btn',
    transparentReportBtn: 'transparent-report-btn',
    transparentIcoBtn: 'transparent-ico-btn',
    detailsInputText: 'details-input-text',
    editBtn: 'edit-btn',
    error: 'error',
    orangeBtn: 'orange-btn',
    orangeTransparentBtn: 'orange-transparent-btn',
    orangeTransparentModalBtn: 'orange-transparent-modal-btn',
    addNewBtn: 'add-new-btn',
    transparentBlueBtn: 'trans-blue-btn',
    blueOutlineBtn: 'blue-outline-btn',
    orangeHoverLink: 'orange-hover-link',
    secDarkGrayBtn: 'sec-dark-gray-btn',
    linkBtn: 'link-btn',
    sidebarBtn: 'sidebar-btn',
    transDelBtn: 'trans-del-btn',
    primaryTransparent: 'primary-transparent',
    blueBtn: 'blue-btn',
    selectRounded: 'select-rounded',
    // TODO: not used by FCA because issues with bringing ds theme into app (FCA has own variant def)
    selectCheckbox: 'select-checkbox',
};
const theme = extendTheme({
    colors: {
        brand: {
            primBlue: '#003B5D',
            primOrange: {
                30: '#FBDABD',
            },
            secOrange: '#F6862A',
            modalOrange: '#DA1100',
            deleteError: '#DA1100',
            secDarkGray: '#818584',
            errRed: '#F74440',
            wrnYellow: '#FFBE18',
            successGreen: '#15803D',
            primLightGrey: '#C0C2C1',
            primWhite: '#FAF9F7',
            secGrey: '#2E3532',
            secLightGrey: '#F2F3F4',
            tertBlue: '#6C899E',
        },
        secondaryDarkGray: {
            300: '#C0C2C1',
            800: '#2E3532',
        },
    },
    fonts: {
        nav: 'montserrat',
        heading: 'montserrat',
        body: 'montserrat',
        label: 'montserrat',
    },
    styles: {
        global: {
            a: {
                _hover: {
                    textDecoration: 'underline',
                },
            },
        },
    },
    layerStyles: {
        thead: {
            bg: '#F2F3F4',
        },
    },
    components: {
        FormLabel: {
            baseStyle: {
                boxSizing: 'border-box',
                color: appColors.PRIM_BLUE,
                fontWeight: 'semibold',
                fontSize: 14,
                mb: '5px',
                pl: '18px',
            },
        },
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#003B5D',
                    },
                },
            },
        },
        Text: {
            variants: {
                'details-input-text': {
                    color: appColors.TEXT_COLOR,
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    textAlign: 'left',
                    pl: '18px',
                },
            },
            error: {
                color: 'red.600',
                pt: 1,
                pl: 1,
                fontSize: 'sm',
            },
        },
        Button: {
            variants: {
                'sidebar-btn': {
                    background: '#EDF2F7',
                    dropShadow: 'rgba(0, 0, 0, 0.25)',
                    borderShadow: '50px 0px 0px 50px',
                    opacity: 0.8,
                    borderRadius: '50px 0px 0px 50px',
                    p: 0,
                    m: 0,
                },
                'transparent-ico-btn': {
                    bg: 'transparent',
                    _hover: { bg: 'transparent' },
                    _active: { boxShadow: 'none' },
                },
                'gradient-btn': {
                    bgGradient: 'linear(92.97deg, brand.primBlue 15.24%, #6C899E 112.39%)',
                    color: 'white',
                    textTransform: 'uppercase',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'blue-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    textTransform: 'uppercase',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'transparent-report-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderColor: 'brand.primBlue',
                    borderWidth: '1px',
                    borderRadius: '10px',
                    h: '30px',
                    fontWeight: 500,
                    _hover: { bg: 'brand.primBlue', color: 'white' },
                    transitionDuration: appValues.TRANSITION_DURATION,
                },
                'trans-del-btn': {
                    bg: 'transparent',
                    color: 'brand.deleteError',
                    borderWidth: '1px',
                    borderColor: 'brand.deleteError',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'edit-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    fontSize: 18,
                    fontWeight: 400,
                    borderRadius: '32px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    w: '152px',
                    h: '40px',
                },
                'orange-btn': {
                    bg: 'brand.secOrange',
                    color: 'white',
                    borderRadius: '60px',
                    fontWeight: 700,
                    fontSize: 14,
                },
                'orange-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.secOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.secOrange',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                },
                'orange-transparent-modal-btn': {
                    bg: 'transparent',
                    color: 'brand.modalOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.modalOrange',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                },
                'add-new-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: 18,
                    '&[disabled]': {
                        '&:hover': {
                            bg: 'brand.primBlue',
                            color: 'white',
                        },
                    },
                },
                'blue-outline-btn': {
                    bg: 'white',
                    color: appColors.PRIM_BLUE,
                    border: `1px solid ${appColors.PRIM_BLUE}`,
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                },
                'trans-blue-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                },
                'sec-dark-gray-btn': {
                    bg: '#818584',
                    color: 'white',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                    opacity: 1,
                },
                'link-btn': {
                    bg: 'transparent',
                    fontWeight: 500,
                    px: 0,
                    borderRadius: 0,
                    py: '3px',
                    h: 'auto',
                },
                'primary-transparent': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    p: 0,
                    h: 'fit-content',
                    fontSize: '14px',
                },
                'blue-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    height: '36px',
                },
            },
        },
        // TODO: not used by FCA because issues with bringing ds theme into app (FCA has own variant def)
        Checkbox: {
            variants: {
                'select-checkbox': {
                    icon: {
                        color: 'brand.secOrange',
                    },
                    control: {
                        borderColor: 'brand.primBlue',
                        backgroundColor: 'brand.primWhite',
                        _disabled: {
                            backgroundColor: 'brand.secLightGrey',
                            borderColor: 'brand.secDarkGray',
                        },
                    },
                },
            },
        },
        Link: {
            baseStyle: {
                fontWeight: 500,
            },
            variants: {
                'orange-hover-link': {
                    p: '2px',
                    _hover: {
                        bg: 'brand.secOrange',
                        color: 'white',
                        textDecoration: 'none',
                    },
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    borderRadius: '5px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                    h: '40px',
                    boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            defaultProps: {
                size: null,
            },
        },
        NumberInput: {
            baseStyle: {
                field: {
                    borderRadius: '50px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                    h: '40px',
                    boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            defaultProps: {
                size: null,
            },
        },
        Select: {
            baseStyle: {
                field: {
                    borderRadius: '5px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                    h: '40px',
                    boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            defaultProps: {
                size: null,
            },
        },
        Tabs: {
            variants: {
                unstyled: {
                    parts: ['tab'],
                    tab: {
                        color: 'brand.primBlue',
                        fontSize: 24,
                        fontWeight: 700,
                        _selected: {
                            borderBottom: '4px solid #F6862A',
                        },
                    },
                },
                styled: {
                    parts: ['tab', 'tabpanel'],
                    tab: {
                        backgroundColor: '#6C899E',
                        color: 'white',
                        _selected: {
                            backgroundColor: '#F2F3F4',
                            color: 'secondaryDarkGray.800',
                            borderRadius: '4px 4px 0px 0px',
                            boxShadow: '0px 0px 4px 0px',
                        },
                        fontSize: '12px',
                        boxShadow: '0px 0px 4px 0px',
                        borderRadius: '4px 4px 0px 0px',
                    },
                    tabpanel: {
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                    },
                },
            },
            defaultProps: {
                variant: 'unstyled',
            },
        },
        Table: {
            parts: ['table', 'th', 'td', 'tr'],
            baseStyle: {
                table: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 10px',
                    border: 'none',
                    fontVariantNumeric: 'none',
                },
                th: {
                    fontWeight: 700,
                    textTransform: 'capitalize',
                    fontSize: 14,
                    letterSpacing: 'normal',
                    border: 'none',
                },
                td: {
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                },
                tr: {
                    borderRadius: '50px',
                    h: '65px',
                },
            },
        },
    },
});
export default theme;
