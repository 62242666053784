import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Accordion, Box, Center, Divider, Flex, IconButton, Image, Link, Stack, VisuallyHidden, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { IAppNames } from '@frontend/domain/models/App';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { environment } from '../../config/environment';
import { OptimizeBoldIcon, OptimizeIcon, OrganizeBoldIcon, OrganizeIcon, OrgSettingsBoldIcon, OrgSettingsIcon, PrioritizeBoldIcon, PrioritizeIcon, SupportBoldIcon, SupportIcon, SystemBoldIcon, SystemIcon, } from '../../config/icons';
import { ExternalPaths, Paths } from '../../config/paths';
import logoFull from '../../images/logoFull.png';
import LogoSmall from '../../images/LogoSmall.png';
import { SystemRoleEnum } from '../../objects/UserData';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useReports } from '../context/ReportsContext';
import { useCurrentUserAppAccess } from '../hooks/useAppAccess';
import { NavBarLink } from './NavBarLink';
import { NavBarLinkGroup } from './NavBarLinkGroup';
import { ProfileControl } from './ProfileControl';
export var NavLinkName;
(function (NavLinkName) {
    NavLinkName["ORGANIZE"] = "Organize";
    NavLinkName["PRIORITIZE"] = "Prioritize";
    NavLinkName["OPTIMIZE"] = "Optimize";
    NavLinkName["SYSTEM_ADMIN"] = "System Admin";
    NavLinkName["ORGANIZATION_SETTINGS"] = "Organization Settings";
    NavLinkName["SUPPORT"] = "Support";
})(NavLinkName || (NavLinkName = {}));
export const CurrentNavBar = ({ sidebarExpanded, currSidebarWidth, setSidebarExpanded, orgId, orgIdentity, userRole, }) => {
    const navigate = useNavigate();
    const { reports } = useReports();
    const { [IAppNames.PIQ]: hasPIQAccess } = useCurrentUserAppAccess();
    const rememberOrgFeatureFlag = useFeatureFlag(FeatureFlags.REMEMBER_ORG);
    const getFilteredReports = (appContext) => {
        const filteredReports = orgId
            ? reports
                .filter((report) => report.appContext === appContext)
                .map((report) => {
                const newLink = {
                    baseLink: ExternalPaths.getReportUrl(orgId, report.reportId.toString()),
                    title: report.reportName,
                };
                return newLink;
            })
            : [];
        return filteredReports;
    };
    const organizeSubLinks = [
        {
            baseLink: orgId && rememberOrgFeatureFlag
                ? ExternalPaths.getFCAOrgUrl(orgId)
                : ExternalPaths.getFCAUrl(),
            subLinks: [environment.fcaUrl, ExternalPaths.getFCAUrl(), '/sites'],
            title: 'FCA Tool',
        },
    ];
    const organizeNavGroup = {
        iconSrc: OrganizeIcon,
        selectedIcon: OrganizeBoldIcon,
        baseLink: environment.fcaUrl,
        title: NavLinkName.ORGANIZE,
        subLinks: organizeSubLinks,
        reports: getFilteredReports(NavLinkName.ORGANIZE),
    };
    const prioritizeSubLinks = [
        {
            baseLink: orgId && rememberOrgFeatureFlag
                ? ExternalPaths.getMyOrgProjectsUrl(orgId)
                : ExternalPaths.getMyProjectsUrl(),
            title: 'myProjects',
        },
        {
            baseLink: orgId && rememberOrgFeatureFlag
                ? ExternalPaths.getMyOrgScenariosUrl(orgId)
                : ExternalPaths.getMyScenariosUrl(),
            title: 'myScenarios',
        },
        {
            baseLink: orgId && rememberOrgFeatureFlag
                ? ExternalPaths.getMyOrgAssetsUrl(orgId)
                : ExternalPaths.getMyAssetsUrl(),
            title: 'myAssets',
        },
        ...(hasPIQAccess
            ? [
                {
                    baseLink: ExternalPaths.getMyOpportunitiesUrl(orgIdentity),
                    title: 'myOpportunities',
                },
            ]
            : []),
    ];
    const prioritizeNavGroup = {
        iconSrc: PrioritizeIcon,
        selectedIcon: PrioritizeBoldIcon,
        baseLink: environment.plannerUrl,
        title: NavLinkName.PRIORITIZE,
        subLinks: prioritizeSubLinks,
        reports: getFilteredReports(NavLinkName.PRIORITIZE),
    };
    const optimizeNavGroup = {
        iconSrc: OptimizeIcon,
        selectedIcon: OptimizeBoldIcon,
        title: NavLinkName.OPTIMIZE,
        reports: getFilteredReports(NavLinkName.OPTIMIZE),
    };
    return (_jsxs(Flex, { onClick: () => {
            !sidebarExpanded && setSidebarExpanded(true);
        }, bg: '#FAF9F7', w: currSidebarWidth, h: '100vh', overflow: 'hidden', right: 'inherit', position: 'fixed', flexDirection: 'column', zIndex: 30, boxSizing: 'border-box', pl: '5px', transitionDuration: appValues.TRANSITION_DURATION, overflowY: 'auto', children: [_jsx(VisuallyHidden, { children: _jsx(Link, { href: '#main', className: 'sr-only', children: "Skip to Main Content" }) }), _jsx("nav", { role: 'navigation', "aria-label": 'side bar', children: _jsxs(Stack, { h: '100vh', justifyContent: 'space-between', spacing: 0, boxSizing: 'border-box', pb: '23px', children: [_jsxs(Box, { children: [_jsx(Center, { h: '100px', mb: '22px', tabIndex: 0, children: sidebarExpanded ? (_jsx(Link, { as: RouterLink, replace: true, to: Paths.HOME, children: _jsx(Image, { src: logoFull, alt: 'My Site IQ Home Button', onClick: () => {
                                                navigate(Paths.HOME);
                                            }, pt: '20px' }) })) : (_jsx(Link, { as: RouterLink, replace: true, to: Paths.HOME, children: _jsx(Image, { src: LogoSmall, alt: 'My Site IQ Home' }) })) }), sidebarExpanded && (_jsx(IconButton, { onClick: () => {
                                        sidebarExpanded &&
                                            setSidebarExpanded(false);
                                    }, icon: _jsx(ChevronLeftIcon, { boxSize: '25px' }), "aria-label": 'close-sidebar', position: 'absolute', right: '-10px', top: '45%', boxSizing: 'border-box', boxSize: '30px', variant: variants.sidebarBtn })), !sidebarExpanded && (_jsx(IconButton, { onClick: () => {
                                        !sidebarExpanded &&
                                            setSidebarExpanded(true);
                                    }, icon: _jsx(ChevronRightIcon, { boxSize: '25px' }), "aria-label": 'open-sidebar', position: 'absolute', right: '-10px', top: '45%', boxSizing: 'border-box', boxSize: '30px', variant: variants.sidebarBtn })), _jsxs(Accordion, { allowToggle: true, children: [_jsx(NavBarLinkGroup, { groupDetails: organizeNavGroup, expanded: sidebarExpanded, orgIdentity: orgIdentity }), _jsx(NavBarLinkGroup, { groupDetails: prioritizeNavGroup, expanded: sidebarExpanded, orgIdentity: orgIdentity }), _jsx(NavBarLinkGroup, { groupDetails: optimizeNavGroup, expanded: sidebarExpanded, orgIdentity: orgIdentity })] }), _jsx(Divider, { width: '95%', my: '15px' }), _jsxs(Accordion, { children: [userRole &&
                                            (userRole === SystemRoleEnum.ORG_APPROVER ||
                                                userRole ===
                                                    SystemRoleEnum.SUPER_ADMIN) && (_jsx(NavBarLink, { iconSrc: SystemIcon, selectedIcon: SystemBoldIcon, baseLink: Paths.SYSTEM_ADMIN, title: NavLinkName.SYSTEM_ADMIN, expanded: sidebarExpanded })), orgId && (_jsx(NavBarLink, { iconSrc: OrgSettingsIcon, selectedIcon: OrgSettingsBoldIcon, baseLink: `${Paths.ORG_DETAILS}/${orgId}`, title: NavLinkName.ORGANIZATION_SETTINGS, expanded: sidebarExpanded })), _jsx(NavBarLink, { iconSrc: SupportIcon, selectedIcon: SupportBoldIcon, baseLink: Paths.SUPPORT, title: NavLinkName.SUPPORT, expanded: sidebarExpanded })] })] }), _jsx(ProfileControl, { expanded: sidebarExpanded })] }) })] }));
};
