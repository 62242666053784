export class AssetGroupService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Retrieves asset group information based on the organization ID provided
     * @param organizationId ID of the organization to load asset groups for
     * @returns AssetGroupResponse OK
     * @throws ApiError
     */
    getApiV1AssetGroupLoad(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/AssetGroup/load/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
