var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SystemRoleEnum, } from '../objects/UserData';
const baseUrl = '/user'; // this should be... users??
const platformApiPath = '/User';
export default class UserService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    getAllAdmins() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api
                .get(`${baseUrl}/type/admins`)
                .then((backendUsers) => backendUsers.map((backendUser) => this.getUserFromBackendUser(backendUser)));
        });
    }
    getUserByEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api
                .get(`${baseUrl}/${email}`)
                .then((backendUser) => this.getUserFromBackendUser(backendUser));
        });
    }
    sendInvite(user) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${platformApiPath}/invite`, user);
        });
    }
    configureUser(user) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${platformApiPath}/configure`, user);
        });
    }
    verifyUser(activateUser) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${platformApiPath}/verify`, activateUser);
        });
    }
    viewUser(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = userId ? { userId } : undefined;
            return yield this.platformApi
                .getWithQueryParams(`/api/v1${platformApiPath}/view`, {}, params)
                .then((platformUser) => this.getUserFromPlatformUser(platformUser));
        });
    }
    getUserFromPlatformUser(platformUser) {
        var _a, _b, _c, _d;
        return {
            id: platformUser.userId,
            userIdentity: platformUser.userIdentity,
            firstName: platformUser.firstName,
            lastName: platformUser.lastName,
            email: platformUser.emailAddress,
            phoneNumber: platformUser.phoneNumber,
            jobTitle: platformUser.jobTitle,
            systemRole: (_a = platformUser.systemRole) !== null && _a !== void 0 ? _a : SystemRoleEnum.USER,
            organizationRoles: (_b = platformUser.organizationRoles) !== null && _b !== void 0 ? _b : [],
            facilityRoles: (_c = platformUser.facilityRoles) !== null && _c !== void 0 ? _c : [],
            apps: (_d = platformUser.apps) !== null && _d !== void 0 ? _d : [],
            isDeleted: platformUser.isDeleted,
        };
    }
    getUserFromBackendUser(backendUser) {
        var _a, _b, _c, _d;
        return {
            id: backendUser.id,
            userIdentity: backendUser.userIdentity,
            firstName: backendUser.firstName,
            lastName: backendUser.lastName,
            email: backendUser.email,
            phoneNumber: backendUser.phoneNumber,
            jobTitle: backendUser.jobTitle,
            systemRole: (_a = backendUser.systemRole) !== null && _a !== void 0 ? _a : SystemRoleEnum.USER,
            organizationRoles: (_b = backendUser.organizationRoles) !== null && _b !== void 0 ? _b : [],
            facilityRoles: (_c = backendUser.facilityRoles) !== null && _c !== void 0 ? _c : [],
            apps: (_d = backendUser.apps) !== null && _d !== void 0 ? _d : [],
            isDeleted: backendUser.isDeleted,
        };
    }
    resendInvite(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${platformApiPath}/invite/resend`, userId);
        });
    }
    orgUsersList(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi
                .getWithQueryParams(`/api/v1${platformApiPath}/list`, {}, orgIdentity)
                .then((platformUsers) => platformUsers.map((platformUser) => this.getUserFromPlatformUser(platformUser)));
        });
    }
    getUsersList() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi
                .getWithQueryParams(`/api/v1${platformApiPath}/list`)
                .then((platformUsers) => platformUsers.map((platformUser) => this.getUserFromPlatformUser(platformUser)));
        });
    }
    offboardUser(users) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${platformApiPath}/offboard`, users);
        });
    }
}
