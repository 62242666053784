var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Button, ListItem, useDisclosure, useToast, } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchOrgById, memoizedSelectOrganization, } from '../../../store/orgSlice';
import { TagIcon } from '../../../config/icons';
import { EditTagModal } from './EditTagModal';
import { DeleteModal } from '@/sharedComponents/DeleteModal';
import { deleteTag } from '@/services/api/orgDetailsAPI';
export const TagCard = ({ canEdit, orgIdentity, tag, orgTags, selectedTag, setSelectedTag, showActions, setShowActions, }) => {
    const { isOpen: isOpenEditPhotoTag, onOpen: onOpenEditPhotoTag, onClose: onCloseEditPhotoTag, } = useDisclosure();
    const { isOpen: isOpenDeletePhotoTag, onOpen: onOpenDeletePhotoTag, onClose: onCloseDeletePhotoTag, } = useDisclosure();
    const toast = useToast();
    const dispatch = useAppDispatch();
    const { org: orgCurrent } = useAppSelector(memoizedSelectOrganization);
    const onDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (tag.tagIdentity) {
                yield deleteTag(tag.tagIdentity);
                toast({
                    title: 'Tag deleted',
                    status: 'success',
                    isClosable: true,
                });
                orgCurrent && dispatch(fetchOrgById(orgCurrent.id));
                onCloseDeletePhotoTag();
            }
        }
        catch (error) {
            console.error(error);
            toast({
                title: 'Delete failed',
                description: 'Please try again',
                status: 'error',
                isClosable: true,
            });
        }
    });
    return (_jsxs(Box, { bg: tag.tagIdentity === (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.tagIdentity)
            ? '#FEF3EA'
            : 'transparent', pl: tag.tagIdentity === (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.tagIdentity) ? '5px' : '0px', onClick: () => {
            if (canEdit) {
                if ((selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.tagIdentity) === tag.tagIdentity) {
                    setSelectedTag(undefined);
                    setShowActions(false);
                }
                else {
                    setSelectedTag(tag);
                    setShowActions(true);
                }
            }
        }, display: 'flex', justifyContent: 'space-between', children: [_jsxs(Box, { display: 'flex', children: [_jsx(TagIcon, { fill: tag.color, alignSelf: 'center', "data-testid": 'tag-icon' }), _jsx(ListItem, { ml: '15px', alignSelf: 'center', children: tag.name })] }), _jsxs(Box, { display: 'flex', children: [_jsx(Button, { visibility: canEdit &&
                            showActions &&
                            tag.tagIdentity === (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.tagIdentity)
                            ? 'visible'
                            : 'hidden', onClick: onOpenEditPhotoTag, leftIcon: _jsx(EditIcon, {}), variant: 'ghost', fontSize: '14px', id: `rename-tag-${tag.tagIdentity}`, children: "Rename" }), _jsx(Button, { visibility: canEdit &&
                            showActions &&
                            tag.tagIdentity === (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.tagIdentity)
                            ? 'visible'
                            : 'hidden', onClick: onOpenDeletePhotoTag, leftIcon: _jsx(DeleteIcon, {}), variant: 'ghost', fontSize: '14px', id: `delete-tag-${tag.tagIdentity}`, children: "Delete" })] }), canEdit && (_jsx(_Fragment, { children: _jsx(EditTagModal, { tagToEdit: tag, orgIdentity: orgIdentity, orgTags: orgTags, isOpen: isOpenEditPhotoTag, onClose: onCloseEditPhotoTag }) })), canEdit && (_jsx(_Fragment, { children: _jsx(DeleteModal, { isOpen: isOpenDeletePhotoTag, onClose: onCloseDeletePhotoTag, deleteItem: onDelete, title: 'Delete Tag', description: 'You are about to remove a tag. This action will remove the tag from all photos in organization and is not reversible. Are you sure you want to continue?' }) }))] }));
};
