import { environment } from './environment';
export const Paths = {
    HOME: '/',
    ORGS: '/organizations',
    REPORT: '/report',
    REPORT_DYNAMIC: '/report/:oid/:rid',
    SYSTEM_ADMIN: '/systemAdmin',
    ORG_DETAILS: '/organizationDetails',
    ORG_DETAILS_CREATE: '/organizationDetails/create',
    ORG_DETAILS_ID: '/organizationDetails/:oid',
    USER_PROFILE: '/userProfile',
    SUPPORT: '/support',
    ACTIVATE_PAGE: '/activate',
    LOGIN: '/login',
    LOGOUT: '/logout',
    REPORTS: '/reports',
};
export const ExternalPaths = {
    // fca
    getFCAUrl: () => `${environment.fcaUrl}/organizations/`,
    getFCAOrgUrl: (oid) => `${environment.fcaUrl}/organizations/${oid}/sites`,
    getMyGalleryUrl: () => `${environment.fcaUrl}/gallery`,
    getMyOrgGalleryUrl: (oid) => `${environment.fcaUrl}/gallery/${oid}`,
    // cp
    getMyAssetsUrl: () => `${environment.plannerUrl}/myassets`,
    getMyOrgAssetsUrl: (oid) => `${environment.plannerUrl}/myassets/${oid}`,
    getMyProjectsUrl: () => `${environment.plannerUrl}/projects`,
    getMyOrgProjectsUrl: (oid) => `${environment.plannerUrl}/projects/${oid}`,
    getMyScenariosUrl: () => `${environment.plannerUrl}/scenario-planner`,
    getMyOrgScenariosUrl: (oid) => `${environment.plannerUrl}/scenario-planner/${oid}`,
    // piq
    getMyOpportunitiesUrl: (oid) => `${environment.rootPathUrl}/prioritize/opportunities/${oid}/facilities`,
    // reports
    getHomeUrl: (oid) => (oid ? `${Paths.HOME}${oid}` : Paths.HOME),
    getSystemAdminUrl: () => Paths.SYSTEM_ADMIN,
    getOrgSettingsUrl: (oid) => oid ? `${Paths.ORG_DETAILS}/${oid}` : Paths.ORG_DETAILS,
    getReportUrl: (oid, rid) => `${Paths.REPORT}/${oid}/${rid}`,
    getOneReportUrl: (oid, wid, rid) => `${Paths.REPORTS}/${oid}/${wid}/${rid}`,
};
export const getPageUrl = (orgId, pathname, onHomePage) => {
    if (pathname.includes(ExternalPaths.getOrgSettingsUrl())) {
        return ExternalPaths.getOrgSettingsUrl(orgId);
    }
    else if (pathname === '/' || onHomePage) {
        return ExternalPaths.getHomeUrl(orgId);
    }
    else {
        return '';
    }
};
