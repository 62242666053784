import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Icon, Text } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { SubLinkBoldIcon, SubLinkIcon } from '../../config/icons';
import { Paths } from '../../config/paths';
import { NavLink } from './NavLink';
export const NavSubLink = ({ linkDetails: { baseLink, subLinks, title }, pl, }) => {
    const loc = window.location.href;
    let isCurrentPage = false;
    if (subLinks) {
        isCurrentPage = subLinks
            .map((link) => loc.includes(link))
            .includes(true);
    }
    else {
        isCurrentPage =
            loc.includes(baseLink) &&
                !loc.includes(Paths.SUPPORT) &&
                !loc.includes(Paths.SYSTEM_ADMIN) &&
                !loc.includes(Paths.ORG_DETAILS) &&
                !loc.includes(Paths.REPORTS);
    }
    return (_jsx(NavLink, { href: baseLink, children: _jsxs(HStack, { pl: pl, mb: '10px', children: [_jsx(Icon, { as: isCurrentPage ? SubLinkBoldIcon : SubLinkIcon, boxSize: isCurrentPage ? '20px' : '15px', mr: isCurrentPage ? '0px' : '5px' }), _jsx(Text, { fontSize: '12px', color: appColors.PRIM_BLUE, textAlign: 'left', fontWeight: isCurrentPage ? 600 : 400, children: title })] }) }));
};
