import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { SystemRoleEnum } from '../../../objects/UserData';
import { DeleteModal } from '../../../sharedComponents/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchAllUserOrgs, selectAllOrgs } from '../../../store/orgSlice';
import { selectUserData } from '../../../store/userDataSlice';
import { deleteDescription, deleteUserTitle, useDeleteUser, } from '../../../utils/handleDelete';
import { canDeleteUser, canResendInvite } from '../../../utils/userRoleUtils';
import ActionsBodyUserModal from '../../OrganizationDetails/Users/ActionsBodyUserModal';
import InvitationSentModal from '../InvitationSentModal';
import ResendInvitationModal from '../ResendInvitationModal';
import { TableType } from '../SystemAdminPage';
import { SystemAdminRow } from '../Table/SystemAdminRow';
import { SystemAdminTable } from '../Table/SystemAdminTable';
import { AddUserModal } from './AddUserModal';
import { UserManagementTableRow } from './UserManagementTableRow';
export const UserManagement = ({ users, onUserUpdate, }) => {
    const userManagementTableHeaders = [
        'Organization',
        'Name',
        'Title',
        'Role',
    ];
    const orgs = useAppSelector(selectAllOrgs);
    const [user, setUser] = useState();
    const currentUser = useAppSelector(selectUserData);
    const [isLoading, setIsLoading] = useState(false);
    const canResendInvitation = canResendInvite(currentUser);
    const dispatch = useAppDispatch();
    const handleDeleteUser = useDeleteUser();
    const { isOpen: isOpenAddUser, onOpen: onOpenAddUser, onClose: onCloseAddUser, } = useDisclosure();
    const { isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isOpenInvitationSent, onOpen: onOpenInvitationSent, onClose: onCloseInvitationSent, } = useDisclosure();
    const { isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser, } = useDisclosure();
    useEffect(() => {
        user &&
            user.organizationRoles &&
            dispatch(fetchAllUserOrgs(user.organizationRoles));
    }, [user === null || user === void 0 ? void 0 : user.id]);
    const onEnd = () => {
        onCloseDeleteUser();
        setUser(undefined);
        onUserUpdate();
    };
    const renderRows = () => {
        if (users) {
            const filteredUsers = filterSystemRoleUsers([SystemRoleEnum.USER], users);
            return (_jsx(_Fragment, { children: filteredUsers.map((user) => {
                    const canDelete = canDeleteUser(currentUser, user);
                    return (user.organizationRoles &&
                        user.organizationRoles.map((orgRole, i) => {
                            const userOrg = orgs.find((org) => org.orgIdentity ===
                                orgRole.organizationIdentity);
                            return (_jsx(SystemAdminRow, { popoverMenu: _jsx(ActionsBodyUserModal, { user: user, canDelete: canDelete, onOpenAddUser: onOpenAddUser, canResendInvitation: canResendInvitation, setIsLoading: setIsLoading, onOpenInvitationSent: onOpenInvitationSent, onCloseInvitationSent: onCloseInvitationSent, onCloseResendInvitation: onCloseResendInvitation, setUser: setUser, onOpenDelete: onOpenDeleteUser }), user: user, orgs: orgs, children: _jsx(UserManagementTableRow, { user: user, orgRole: orgRole, orgName: userOrg === null || userOrg === void 0 ? void 0 : userOrg.name }) }, `${user.id} + ${i}`));
                        }));
                }) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { width: '100%', display: 'flex', justifyContent: 'flex-end', children: _jsx(Button, { onClick: () => {
                        setUser(undefined);
                        onOpenAddUser();
                    }, w: '124px', h: '36px', marginBottom: '15px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New" }) }), _jsx(SystemAdminTable, { tableName: TableType.user, tableHeaders: userManagementTableHeaders, children: renderRows() }), _jsx(AddUserModal, { isOpen: isOpenAddUser, onClose: () => {
                    onCloseAddUser();
                    onUserUpdate();
                }, availableOrgs: orgs, originalUser: user }), user && user.email && isLoading && (_jsx(ResendInvitationModal, { email: user.email, isOpen: isOpenResendInvitation, onClose: onCloseResendInvitation })), _jsx(InvitationSentModal, { isOpen: isOpenInvitationSent, onClose: onCloseInvitationSent }), user && (_jsx(DeleteModal, { isOpen: isOpenDeleteUser, onClose: onCloseDeleteUser, deleteItem: () => handleDeleteUser(user, onEnd), title: deleteUserTitle(user), description: deleteDescription((user === null || user === void 0 ? void 0 : user.firstName) + ' ' + (user === null || user === void 0 ? void 0 : user.lastName)) }))] }));
};
export const filterSystemRoleUsers = (roles, users) => {
    return users.filter((user) => roles.find((role) => role === user.systemRole));
};
