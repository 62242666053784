var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
export function getOrg() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getOrg();
    });
}
export function getAllOrgsSnapshots() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getAllOrgsSnapshots();
    });
}
export function getAllOrgs() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getAllOrgs();
    });
}
export function getAllOrgsWithRelations(relations) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getAllOrgsWithRelations(relations);
    });
}
export function getOrgById(id, relations) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getOrgById(id, relations);
    });
}
export function getAmountOfUsersForOrg(orgId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.getAmountOfUsersForOrg(orgId);
    });
}
export function deleteOrgById(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.deleteOrgById(id);
    });
}
export function archiveOrgs(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.archiveOrgs(body);
    });
}
export function removeUserFromOrg(oid, uid) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.orgService.deleteUserFromOrg(oid, uid);
    });
}
export function updateOrg(id, org) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.orgService.updateOrg(id, org);
    });
}
export function createOrg(org) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.orgService.createOrg(org);
    });
}
export function addSiteToOrg(oid, newSite) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.orgService.addSiteToOrg(oid, newSite);
    });
}
export function updateSite(id, site) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.siteService.updateSite(id, site);
    });
}
export function addFacilityToSite(sid, newFacility) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.siteService.addFacilityToSite(sid, newFacility);
    });
}
export function uploadFacilitiesFromCSV(sid, file) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.siteService.uploadFacilitiesFromCSV(sid, file);
    });
}
export function updateFacility(id, facility) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.siteService.updateFacility(id, facility);
    });
}
export function addFacilityAddition(fidentity, newFacilityAddition) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.facilityService.addAddition(fidentity, newFacilityAddition);
    });
}
export function getAdditionsForFacility(fid) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.facilityService.getAdditionsForFacility(fid);
    });
}
export function updateFacilityAddition(faid, facilityAddition) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.facilityService.updateFacilityAddition(faid, facilityAddition);
    });
}
export function addContactToOrg(oid, newContact) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.orgService.addContactToOrg(oid, newContact);
    });
}
export function updateContact(cid, contact) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.orgService.updateContact(cid, contact);
    });
}
export function getTagsForOrg(orgIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.tagService.getTagsForOrg(orgIdentity);
    });
}
export function addTagToOrg(orgIdentity, newTag) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.tagService.addTagToOrg(orgIdentity, newTag);
    });
}
export function updateTag(tagIdentity, tagToUpdate) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.tagService.updateTag(tagIdentity, tagToUpdate);
    });
}
export function deleteTag(tagIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.tagService.deleteTag(tagIdentity);
    });
}
