import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Center, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../components/layouts/PageLayout';
import { LogoutIcon } from '../config/icons';
import { Paths } from '../config/paths';
export const NoAssociatedData = () => {
    const navigate = useNavigate();
    return (_jsx(PageLayout, { children: _jsx(Center, { h: '100vh', children: _jsxs(VStack, { children: [_jsx(Text, { children: "No information is associated with this account." }), _jsx(Button, { onClick: () => navigate(Paths.LOGOUT), leftIcon: _jsx(LogoutIcon, { boxSize: '25px', mr: '10px' }), color: 'brand.primBlue', children: "Logout" })] }) }) }));
};
