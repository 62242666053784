export const convertOrgContactToIUserContact = (user) => {
    return {
        identity: user.userIdentity,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        phoneNumber: user.phoneNumber || undefined,
        jobTitle: user.jobTitle || undefined,
    };
};
