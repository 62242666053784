var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toast } from '../../ToastContainer';
import { defaultOptions, } from './responseWrapperInterfaces';
/**
 * Handles Api request data by wrapping a request promise to handle success/errors optionally through Chakra toast
 * Must be initialized within ChakraProvider due to the import and use of 'useToast'
 * @param options
 * @returns wrapper method to handle responses
 */
export const usePlatformResponseWrapper = () => {
    const getErrorDescription = (errorBody, err) => {
        if (errorBody) {
            if (errorBody.errors) {
                return JSON.stringify(errorBody.errors);
            }
            else {
                return errorBody.toString();
            }
        }
        else {
            return err.toString();
        }
    };
    const handleResponse = (response, options = defaultOptions) => __awaiter(void 0, void 0, void 0, function* () {
        const { success, error } = options;
        return response
            .then((res) => {
            if (success.enabled) {
                toast({
                    title: success.label,
                    status: 'success',
                    isClosable: true,
                });
            }
            return res;
        })
            .catch((err) => {
            const errorBody = err.body;
            const title = errorBody && errorBody.title
                ? errorBody.title
                : error.label;
            toast({
                title: title,
                status: 'error',
                description: getErrorDescription(errorBody, err),
                isClosable: true,
            });
            return Promise.reject(err);
        });
    });
    return { handleResponse };
};
