import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, VStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '../../../config/icons';
import { Paths } from '../../../config/paths';
import { SystemRoleEnum } from '../../../objects/UserData';
import { useAppDispatch } from '../../../store/hooks';
import { setOrganization, toggleOrgsCreateMode, toggleOrgsEditMode, } from '../../../store/orgSlice';
import { DeleteModal } from '@/sharedComponents/DeleteModal';
import { FeatureFlags, useFeatureFlag } from '@/store/featureFlag';
import { deleteDescription, useArchiveOrg } from '@/utils/handleDelete';
const ActionsBodyOrgModal = ({ user, orgToArchive, }) => {
    const archiveEnabled = useFeatureFlag(FeatureFlags.FF_ARCHIVE_ORG);
    const dispatch = useAppDispatch();
    const handleArchiveOrg = useArchiveOrg();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const onEnd = () => {
        onClose();
        dispatch(setOrganization(undefined));
        navigate(Paths.HOME);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ArrowRight, { position: 'absolute', right: '-15px', top: '18px' }), _jsx(VStack, { w: '90%', children: _jsxs(Box, { mt: '5px', fontSize: '12px', fontWeight: 500, children: [_jsx(Box, { mb: '12px', onClick: () => {
                                dispatch(toggleOrgsCreateMode(false));
                                dispatch(toggleOrgsEditMode(true));
                                dispatch(setOrganization(undefined));
                                navigate(`${Paths.ORG_DETAILS}/${orgToArchive.id}`);
                            }, as: 'button', cursor: 'pointer', children: "Edit" }), user && user.systemRole === SystemRoleEnum.SUPER_ADMIN && (_jsx(Box, { onClick: () => {
                                onOpen();
                            }, cursor: 'pointer', as: 'button', children: archiveEnabled ? 'Archive' : 'Delete' }))] }) }), _jsx(DeleteModal, { isOpen: isOpen, onClose: onClose, deleteItem: () => {
                    handleArchiveOrg(orgToArchive, onEnd);
                }, title: archiveEnabled
                    ? 'Archive Organization'
                    : 'Delete Organization', description: archiveEnabled
                    ? deleteDescription(orgToArchive.name, 'archive')
                    : deleteDescription(orgToArchive.name), buttonText: archiveEnabled ? 'Archive' : 'Delete' })] }));
};
export default ActionsBodyOrgModal;
