import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Image } from '@chakra-ui/react';
import { IAppNames } from '@frontend/domain/models/App';
import React from 'react';
import { useAppAccess } from '../components/hooks/useAppAccess';
import cpIcon from '../images/CP_nav_icon.svg';
import fcaIcon from '../images/FCA_nav_icon.svg';
import piqIcon from '../images/PIQ_nav_icon.svg';
import reportsIcon from '../images/Reports_nav_icon.svg';
export const UserApps = ({ user, org }) => {
    const { [IAppNames.REPORTS]: userHasReportsAccess, [IAppNames.FCA]: userHasFCAAccess, [IAppNames.PLANNER]: userHasPlannerAccess, [IAppNames.PIQ]: userHasPIQAccess, } = useAppAccess(user, org);
    return (_jsxs(HStack, { w: '100px', children: [userHasReportsAccess && (_jsx(Image, { alt: 'Reports logo', src: reportsIcon, mr: '5px', boxSize: '30px' })), userHasFCAAccess && (_jsx(Image, { alt: 'FCA logo', src: fcaIcon, mr: '5px', boxSize: '30px' })), userHasPlannerAccess && (_jsx(Image, { alt: 'Planner logo', src: cpIcon, mr: '5px', boxSize: '30px' })), userHasPIQAccess && (_jsx(Image, { alt: 'PIQ logo', src: piqIcon, mr: '5px', boxSize: '30px' }))] }));
};
