import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Accordion, Box, Center, Divider, Flex, HStack, IconButton, Image, Link, Stack, VisuallyHidden, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { appValues } from '../../theme/constants';
import { variants } from '../../theme/theme';
import { NavBarLinkGroup } from './NavBarLinkGroup';
import { NavLink } from './NavLink';
import { ProfileControl } from './ProfileControl';
export const NavBar = ({ initiallyExpanded = false, links, linkGroups, assets, user, squishContent, children, }) => {
    const [sidebarExpanded, setSidebarExpanded] = useState(initiallyExpanded);
    return (_jsxs(HStack, { h: '100vh', alignItems: 'flex-start', width: '100%', children: [_jsxs(Flex, { onClick: () => {
                    !sidebarExpanded && setSidebarExpanded(true);
                }, bg: '#FAF9F7', w: sidebarExpanded
                    ? appValues.SIDEBAR_WIDTH_FULL
                    : appValues.SIDEBAR_WIDTH_SMALL, h: '100vh', overflow: 'hidden', right: 'inherit', position: 'fixed', flexDirection: 'column', zIndex: 30, boxSizing: 'border-box', pl: '5px', boxShadow: sidebarExpanded ? '2xl' : 'lg', transitionDuration: sidebarExpanded ? appValues.TRANSITION_DURATION : undefined, overflowY: 'auto', children: [_jsx(VisuallyHidden, { children: _jsx(Link, { href: '#main', className: 'sr-only', children: "Skip to Main Content" }) }), _jsx("nav", { role: 'navigation', "aria-label": 'side bar', children: _jsxs(Stack, { h: '100vh', justifyContent: 'space-between', spacing: 0, boxSizing: 'border-box', pb: '23px', children: [_jsxs(Box, { children: [_jsx(Center, { h: '100px', mb: '22px', tabIndex: 0, children: sidebarExpanded ? (_jsx(NavLink, Object.assign({}, links.home, { children: _jsx(Image, { alt: 'My Site IQ Home Button', src: assets === null || assets === void 0 ? void 0 : assets.logoLargeSrc, pt: '20px' }) }))) : (_jsx(NavLink, Object.assign({}, links.home, { children: _jsx(Image, { src: assets === null || assets === void 0 ? void 0 : assets.logoSmallSrc, alt: 'My Site IQ Home' }) }))) }), sidebarExpanded && (_jsx(IconButton, { onClick: () => setSidebarExpanded(!sidebarExpanded), icon: _jsx(ChevronLeftIcon, { boxSize: '25px' }), "aria-label": 'close-sidebar', position: 'absolute', right: '-10px', top: '45%', boxSizing: 'border-box', boxSize: '30px', variant: variants.sidebarBtn })), !sidebarExpanded && (_jsx(IconButton, { onClick: () => {
                                                !sidebarExpanded &&
                                                    setSidebarExpanded(true);
                                            }, icon: _jsx(ChevronRightIcon, { boxSize: '25px' }), "aria-label": 'open-sidebar', position: 'absolute', right: '-10px', top: '45%', boxSizing: 'border-box', boxSize: '30px', variant: variants.sidebarBtn })), _jsx(Accordion, { allowMultiple: true, children: linkGroups.map((group) => (_jsxs(Box, { children: [_jsx(NavBarLinkGroup, { group: group, expanded: sidebarExpanded }), group.divideAfter && (_jsx(Divider, { width: '95%', my: '15px' }))] }, `nav-group-${group.title}`))) })] }), _jsx(ProfileControl, { expanded: sidebarExpanded, links: links, user: user })] }) })] }), _jsx(Box, { ml: squishContent
                    ? sidebarExpanded
                        ? appValues.SIDEBAR_WIDTH_FULL
                        : appValues.SIDEBAR_WIDTH_SMALL
                    : appValues.SIDEBAR_WIDTH_SMALL, onClick: () => {
                    sidebarExpanded && setSidebarExpanded(false);
                }, w: '100%', children: children })] }));
};
