import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { authConfig } from '../../authConfig';
import { environment } from '../../config/environment';
export const Auth0ProviderWithNavigate = ({ children, }) => {
    const navigate = useNavigate();
    const { domain, clientId, authorizationParams: { redirectUri, platformAudience }, } = authConfig;
    const onRedirectCallback = (appState) => {
        if (appState === null || appState === void 0 ? void 0 : appState.returnTo) {
            environment.appBasename
                ? navigate(appState.returnTo.replace(environment.appBasename, ''))
                : navigate(appState.returnTo);
        }
    };
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, authorizationParams: {
            redirect_uri: redirectUri,
            audience: platformAudience,
            scope: 'email read:current_user update:current_user_metadata',
        }, onRedirectCallback: onRedirectCallback, useRefreshTokens: true, children: children }));
};
