import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Flex, HStack, Image, Text, VStack, } from '@chakra-ui/react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { useReports } from '../../components/context/ReportsContext';
import mySiteIqLogo from '../../images/mySiteIqLogo.gif';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../../objects/UserData';
import { ClientConciergeText } from '../../sharedComponents/ClientConciergeText';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useAppSelector } from '../../store/hooks';
import { memoizedSelectOrganization } from '../../store/orgSlice';
import { selectUserData } from '../../store/userDataSlice';
import { hasRole } from '../../utils/userRoleUtils';
import { RefreshReportBtn } from './RefreshReportBtn';
import { ShareReportBtn } from './ShareReportBtn';
export const ReportContent = ({ onReportLoaded, reportId, retrievalError, embedConfig, token, }) => {
    const { reports, selectReport, currentReport } = useReports();
    const refreshReportsFeatureFlag = useFeatureFlag(FeatureFlags.REFRESH_REPORT_BUTTON);
    const { org } = useAppSelector(memoizedSelectOrganization);
    const user = useAppSelector(selectUserData);
    const [isLoaded, setIsLoaded] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
    const [_, setReport] = useState();
    useEffect(() => {
        if (user && org && currentReport && !currentReport.isPublished) {
            if (!hasRole([
                SystemRoleEnum.SUPER_ADMIN,
                SystemRoleEnum.ORG_APPROVER,
                OrganizationRoleEnum.CLIENT_CONCIERGE,
                OrganizationRoleEnum.LOCAL_ADMIN,
            ], user, org.id)) {
                setNoAccess(true);
            }
        }
    }, [user, currentReport]);
    useEffect(() => {
        if (reportId) {
            selectReport(parseInt(reportId));
            if (currentReport) {
                onReportLoaded(currentReport);
            }
        }
    }, [reportId, reports, currentReport]);
    const renderNoReports = () => {
        if (org === null || org === void 0 ? void 0 : org.isDeleted) {
            return (_jsxs(VStack, { children: [_jsx(Text, { children: "This organization has been deleted." }), ";", org && org.clientConcierge && (_jsx(ClientConciergeText, { concierge: org.clientConcierge }))] }));
        }
        else {
            if (!retrievalError && !isLoaded && !noAccess) {
                return _jsx(Image, { width: '50%', src: mySiteIqLogo });
            }
            else {
                return (_jsxs(VStack, { children: [_jsx(Text, { children: "This report is not available." }), _jsx(ClientConciergeText, { concierge: org === null || org === void 0 ? void 0 : org.clientConcierge })] }));
            }
        }
    };
    return (_jsxs(_Fragment, { children: [currentReport && token && !noAccess && (_jsxs(Box, { height: '100%', width: '100%', display: isLoaded ? 'contents' : 'none', children: [_jsxs(HStack, { pt: '3px', justifyContent: 'end', children: [refreshReportsFeatureFlag &&
                                user &&
                                org &&
                                hasRole([
                                    SystemRoleEnum.SUPER_ADMIN,
                                    SystemRoleEnum.ORG_APPROVER,
                                    OrganizationRoleEnum.CLIENT_CONCIERGE,
                                    OrganizationRoleEnum.LOCAL_ADMIN,
                                    OrganizationRoleEnum.MEMBER,
                                ], user, org.id) &&
                                !currentReport.isCustom && (_jsx(RefreshReportBtn, { onPowerBILoaded: (loadedValue) => {
                                    setIsLoaded(loadedValue);
                                } })), _jsx(ShareReportBtn, { orgKey: org === null || org === void 0 ? void 0 : org.id, reportId: currentReport.reportId })] }), _jsx(PowerBIEmbed, { "data-testid": 'powerbi-embed', embedConfig: embedConfig, eventHandlers: new Map([
                            [
                                'loaded',
                                () => {
                                    console.info('Report is Loaded.');
                                    setIsLoaded(true);
                                },
                            ],
                            [
                                'rendered',
                                () => {
                                    console.info('Report is Rendered.');
                                },
                            ],
                            [
                                'error',
                                (e) => {
                                    console.error(e.details);
                                },
                            ],
                        ]), cssClassName: 'report-style-class', getEmbeddedComponent: (embedObject) => {
                            console.info(`Embedded object of type "${embedObject.embedtype}" received`);
                            setReport(embedObject);
                        } })] })), _jsx(Flex, { h: '100%', alignContent: 'center', display: !isLoaded ? 'contents' : 'none', children: _jsx(Center, { h: '100%', children: renderNoReports() }) })] }));
};
