import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
export const BasicInfo = ({ register, setValue, user, org, }) => {
    const nameOfOrgRegister = register(NAME_OF_ORG_KEY);
    const firstNameRegister = register(FIRST_NAME_KEY);
    const lastNameRegister = register(LAST_NAME_KEY);
    const jobTitleRegister = register(JOB_TITLE_KEY);
    const emailRegister = register(EMAIL_KEY, { pattern: /^\S+@\S+$/i });
    const phoneNumberRegister = register(PHONE_NUMBER_KEY);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        // TODO: adjust value
        setValue(NAME_OF_ORG_KEY, org ? org.name : '');
        setValue(FIRST_NAME_KEY, (_a = user.firstName) !== null && _a !== void 0 ? _a : '');
        setValue(LAST_NAME_KEY, (_b = user.lastName) !== null && _b !== void 0 ? _b : '');
        setValue(JOB_TITLE_KEY, (_c = user.jobTitle) !== null && _c !== void 0 ? _c : '');
        setValue(EMAIL_KEY, (_d = user.email) !== null && _d !== void 0 ? _d : '');
        setValue(PHONE_NUMBER_KEY, (_e = user.phoneNumber) !== null && _e !== void 0 ? _e : '');
    }, [setValue, user]);
    const renderInput = (label, register, disabled, inputType) => {
        return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: label }), _jsx(Input, Object.assign({ type: inputType ? inputType : 'text', isDisabled: disabled }, register))] }));
    };
    return (_jsxs(HStack, { alignItems: 'flex-start', justifyContent: 'space-between', children: [_jsxs(Stack, { w: '48%', spacing: '18px', children: [renderInput('Name of the organization', nameOfOrgRegister, true), renderInput('First Name', firstNameRegister, true), renderInput('Last Name', lastNameRegister, true), renderInput('Job title', jobTitleRegister, true)] }), _jsxs(Stack, { w: '48%', spacing: '18px', children: [renderInput('Email', emailRegister, true, 'email'), renderInput('Phone number', phoneNumberRegister, true, 'tel')] })] }));
};
const NAME_OF_ORG_KEY = 'nameOfOrg';
const JOB_TITLE_KEY = 'jobTitle';
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';
const EMAIL_KEY = 'email';
const PHONE_NUMBER_KEY = 'phoneNumber';
