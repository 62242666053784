var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFeatureFlags } from '../services/api/featureFlagAPI';
export const initialState = {
    featureFlagCache: [],
};
export const fetchFeatureFlags = createAsyncThunk('featureFlags/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return getFeatureFlags();
}));
export const FeatureFlagSlice = createSlice({
    name: 'FeatureFlag',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFeatureFlags.fulfilled, (state, action) => {
            state.featureFlagCache = action.payload;
        });
    },
});
export const selectFeatureFlagCache = (state) => state.featureFlag.featureFlagCache;
export default FeatureFlagSlice.reducer;
