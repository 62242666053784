var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { usePlatformUserApi } from '../services/api/platformUserApiHook';
import { getAllAdmins } from '../services/api/profilePageAPI';
import { hasAccess } from '../utils/userRoleUtils';
const initialUserData = undefined;
export const initialUserDataSlice = {
    userData: initialUserData,
    admins: [],
};
export const fetchUser = createAsyncThunk('user/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    const { viewUser } = usePlatformUserApi();
    return yield viewUser();
}));
export const fetchAllAdmins = createAsyncThunk('users/admin/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getAllAdmins();
}));
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserDataSlice,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllAdmins.fulfilled, (state, action) => {
            state.admins = action.payload;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.userData = action.payload;
        });
    },
});
export const selectUserData = (state) => state.user.userData;
export const selectAccess = (state) => {
    const user = state.user.userData;
    const org = state.organization.organization;
    return hasAccess(user, org);
};
export const selectAdmins = (state) => state.user.admins;
export default userSlice.reducer;
