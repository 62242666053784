import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, HStack, Icon, Text, } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { SubLinkBoldIcon, SubLinkIcon } from '../../config/icons';
import { Paths } from '../../config/paths';
import { NavSubLink } from './NavSubLink';
export const NavBarLinkGroup = ({ groupDetails: { iconSrc, selectedIcon, baseLink, title, subLinks, reports }, expanded, orgIdentity, }) => {
    const loc = window.location.href;
    let isCurrentPage = false;
    if (baseLink && reports.length > 0) {
        const onReport = reports.some((report) => loc.includes(report.baseLink));
        isCurrentPage = loc.includes(baseLink) || onReport;
    }
    else if (baseLink) {
        isCurrentPage = loc.includes(baseLink);
    }
    else if (reports.length > 0) {
        isCurrentPage = reports.some((report) => loc.includes(report.baseLink));
    }
    const hasReports = reports && reports.length > 0;
    return (_jsxs(AccordionItem, { border: 'none', children: [_jsx("h2", { children: subLinks || hasReports ? (_jsxs(AccordionButton, { justifyContent: 'space-between', children: [_jsxs(Flex, { alignItems: 'center', children: [_jsx(Icon, { as: isCurrentPage ? selectedIcon : iconSrc, boxSize: '40px', mr: '20px' }), expanded && (_jsx(Text, { textAlign: 'center', fontWeight: isCurrentPage ? 600 : 400, children: title }))] }), expanded && (subLinks || hasReports) && (_jsx(AccordionIcon, {}))] })) : expanded && !hasReports ? (_jsxs(Flex, { pl: '16px', py: '8px', alignItems: 'center', children: [_jsx(Icon, { as: isCurrentPage ? selectedIcon : iconSrc, boxSize: '40px', mr: '20px' }), _jsx(Text, { textAlign: 'center', fontWeight: isCurrentPage ? 600 : 400, children: title })] })) : (_jsx(Flex, { pl: '16px', py: '8px', alignItems: 'center', children: _jsx(Icon, { as: isCurrentPage ? selectedIcon : iconSrc, boxSize: '40px', mr: '20px' }) })) }), expanded && (subLinks || hasReports) && (_jsx(AccordionPanel, { children: _jsxs(Box, { background: 'white', boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)', borderRadius: '2px', w: '100%', py: '10px', maxHeight: '250px', overflowY: 'auto', alignItems: 'left', children: [subLinks &&
                            subLinks.map((link, index) => (_jsx(NavSubLink, { linkDetails: link, pl: '40px' }, index))), subLinks && subLinks.length > 0 && (_jsx(Divider, { width: '85%', mb: '10px', mx: 'auto' })), orgIdentity && hasReports && (_jsx(_Fragment, { children: _jsxs(HStack, { pl: '40px', mb: '10px', children: [_jsx(Icon, { as: isCurrentPage &&
                                            loc.includes(Paths.REPORTS)
                                            ? SubLinkBoldIcon
                                            : SubLinkIcon, boxSize: isCurrentPage &&
                                            loc.includes(Paths.REPORTS)
                                            ? '20px'
                                            : '15px', mr: isCurrentPage &&
                                            loc.includes(Paths.REPORTS)
                                            ? '0px'
                                            : '5px' }), _jsx(Text, { fontSize: '12px', color: appColors.PRIM_BLUE, textAlign: 'left', fontWeight: isCurrentPage &&
                                            loc.includes(Paths.REPORTS)
                                            ? 600
                                            : 400, children: "Reports" })] }) })), orgIdentity &&
                            hasReports &&
                            reports.map((link, index) => (_jsx(NavSubLink, { linkDetails: link, pl: '53px' }, index)))] }) }))] }));
};
