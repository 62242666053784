import { convertOrgContactToIUserContact } from '../User/UserConversions';
export const convertOrganizationSummaryResponseToOrganizationSummary = (org) => {
    return {
        id: org.organizationId,
        identity: org.organizationIdentity,
        name: org.name,
        smallLogoUrl: org.smallLogoUrl || undefined,
        largeLogoUrl: org.largeLogoUrl || undefined,
        verticalMarket: org.verticalMarket || undefined,
        businessUnit: org.businessUnit,
        state: org.stateOrProvince,
        clientConcierge: org.clientConcierge
            ? convertOrgContactToIUserContact(org.clientConcierge)
            : undefined,
        userCount: org.userCount,
    };
};
export const convertOrganizationToRequest = (org, image, apps) => {
    let logoFile = undefined;
    if (image) {
        logoFile = new File([image], org.logoSmallName ? org.logoSmallName : '', {
            type: image.type,
        });
    }
    const dataToCreate = {
        Name: org.nameOfOrg,
        Address1: org.address.address1,
        Address2: org.address.address2,
        City: org.address.city,
        StateOrProvince: org.address.state,
        PostalCode: org.address.zip,
        BusinessUnit: org.businessUnit,
        CompanyWebsite: org.companyWebsite,
        SmallLogoFile: logoFile,
        LargeLogoFile: logoFile,
        VerticalMarket: org.verticalMarket,
    };
    if (apps && apps.length > 0) {
        apps.forEach((app, index) => {
            const appRequest = convertOrganizationAppToRequest(app);
            dataToCreate[`Apps[${index}].app`] = appRequest.app;
            dataToCreate[`Apps[${index}].remove`] = appRequest.remove;
            dataToCreate[`Apps[${index}].expiresOn`] = appRequest.expiresOn;
        });
    }
    return dataToCreate;
};
const convertOrganizationAppToRequest = (app) => {
    let date = null;
    if (app.expiresOn) {
        date = new Date(Date.parse(app.expiresOn.toString())).toISOString();
    }
    return {
        app: app.app,
        remove: false,
        expiresOn: date,
    };
};
export const convertConfigurationResponseToOrgIds = (configResponse) => {
    return {
        id: configResponse.id,
        identity: configResponse.identity,
    };
};
export const convertOrgSummaryToOrganizationSnapshot = (orgs) => {
    return orgs.map((org) => {
        return {
            id: org.id,
            identity: org.identity,
            name: org.name,
            clientConcierge: org.clientConcierge,
        };
    });
};
