import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Stack, VStack } from '@chakra-ui/react';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { canEditOrg, hasPIQAndNonExpiredAccess, } from '../../../utils/userRoleUtils';
import { AgeAdjustedConditionToggle } from './AgeAdjustedConditionToggle';
import { EscalationFactorContainer } from './EscalationFactorContainer';
import { FacilityGroupersContainer } from './FacilityGroupersContainer';
import { FundingTypesContainer } from './FundingTypesContainer';
import { PrioritizationAlgorithmsContainer } from './PrioritizationAlgorithmsContainer';
import { ProjectTypesContainer } from './ProjectTypesContainer';
export const MyPlanningPage = ({ orgRole, org, }) => {
    const { orgFundingTypes, upsertFundingType, deleteFundingType, orgProjectTypes, upsertProjectType, deleteProjectType, orgEscalationFactor, generalUpdateEscalationFactor, orgAgeAdjustedCondition, updateAgeAdjustedCondition, orgFacilityGroupers, updateFacilityGrouperName, orgPrioritizationAlgorithms, priorityCriteria, upsertPrioritizationAlgorithm, refreshPriorityCriteria, } = usePlannerSettingsContext();
    const canEdit = canEditOrg(orgRole);
    const showGroupers = hasPIQAndNonExpiredAccess(org.apps);
    return (_jsxs(Stack, { children: [_jsxs(HStack, { w: '100%', gap: 10, border: '1px solid #C0C2C1', borderRadius: '1px', justifyContent: 'stretch', alignItems: 'flex-start', p: '40px', mb: '30px', children: [_jsx(Box, { w: showGroupers ? '40%' : '50%', children: _jsx(FundingTypesContainer, { orgFundingTypes: orgFundingTypes, upsertFundingType: upsertFundingType, deleteFundingType: deleteFundingType, canEdit: canEdit }) }), showGroupers && (_jsx(Box, { w: '30%', children: _jsx(FacilityGroupersContainer, { orgFacilityGroupers: orgFacilityGroupers, updateFacilityGrouperName: updateFacilityGrouperName, canEdit: canEdit }) })), _jsxs(VStack, { w: showGroupers ? '30%' : '50%', spacing: 5, children: [_jsx(AgeAdjustedConditionToggle, { canEdit: canEdit, hasAgeAdjustedCondition: orgAgeAdjustedCondition, updateAgeAdjustedCondition: updateAgeAdjustedCondition }), _jsx(EscalationFactorContainer, { orgEscalationFactor: orgEscalationFactor, generalUpdateEscalationFactor: generalUpdateEscalationFactor, canEdit: canEdit })] })] }), _jsxs(HStack, { w: '100%', gap: 10, border: '1px solid #C0C2C1', borderRadius: '1px', alignItems: 'flex-start', p: '40px', mb: '30px', children: [_jsx(Box, { w: '100%', children: _jsx(PrioritizationAlgorithmsContainer, { canEdit: canEdit, orgPrioritizationAlgorithms: orgPrioritizationAlgorithms, upsertPrioritizationAlgorithm: upsertPrioritizationAlgorithm, priorityCriteria: priorityCriteria, refreshPriorityCriteria: refreshPriorityCriteria }) }), _jsx(Box, { w: '100%', children: _jsx(ProjectTypesContainer, { canEdit: canEdit, orgProjectTypes: orgProjectTypes, orgPrioritizationAlgorithms: orgPrioritizationAlgorithms, upsertProjectType: upsertProjectType, deleteProjectType: deleteProjectType }) })] })] }));
};
