import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { PageHeader } from '../../sharedComponents/PageHeader';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchOrgById, memoizedSelectOrganization, selectOrgsCreateMode, } from '../../store/orgSlice';
import { selectUserData } from '../../store/userDataSlice';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
export const PageLayout = ({ title, children, hideOrgsDropdown, }) => {
    const params = useParams();
    const { org } = useAppSelector(memoizedSelectOrganization);
    const currentUser = useAppSelector(selectUserData);
    const createMode = useAppSelector(selectOrgsCreateMode);
    const dispatch = useAppDispatch();
    const universalHeaderFF = useFeatureFlag(FeatureFlags.FF_UNIVERSAL_HEADER);
    useEffect(() => {
        if (!createMode &&
            !window.location.href.includes('/organizationDetails/create') &&
            !universalHeaderFF) {
            const sessionOrg = JSON.parse(
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            window.sessionStorage.getItem('currOrg'));
            const oid = params.oid && params.oid !== (org === null || org === void 0 ? void 0 : org.id)
                ? params.oid
                : !params.oid && !org && sessionOrg
                    ? sessionOrg.id
                    : undefined;
            // not sure why this call is needed so am leaving it for now
            oid && dispatch(fetchOrgById(oid));
        }
    }, [params.oid, currentUser]);
    return (_jsx(ExpandableSidebar, { children: _jsxs(Box, { m: '0px 50px', children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', hideOrgsDropdown: hideOrgsDropdown }), _jsx(Flex, { flexGrow: 1, flexDir: 'column', h: `calc(100vh - ${appValues.HEADER_HEIGHT}px)`, overflowY: 'auto', children: children })] }) }));
};
