import { jsx as _jsx } from "react/jsx-runtime";
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchOrgById, memoizedSelectOrganization } from '../../store/orgSlice';
import { setAvailable } from '../../store/sidebarSlice';
import { ReportContent } from './ReportContent';
import './ReportPage.css';
export const ReportPage = () => {
    var _a;
    const dispatch = useAppDispatch();
    const params = useParams();
    const { org } = useAppSelector(memoizedSelectOrganization);
    const [token, setToken] = useState();
    const [retrievalError, setRetrievalError] = useState(false);
    const [reportTitle, setReportTitle] = useState('Reports');
    const { fetchReportToken } = usePlatformOrgApi();
    const [reportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: undefined,
        accessToken: undefined,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
            },
        },
    });
    useEffect(() => {
        dispatch(setAvailable(true));
    }, []);
    useEffect(() => {
        if (params.oid && (org === null || org === void 0 ? void 0 : org.id) !== params.oid) {
            dispatch(fetchOrgById(params.oid));
        }
        if (params.oid && params.rid) {
            fetchReportToken(parseInt(params.rid))
                .then((token) => {
                setToken(token);
                setRetrievalError(false);
                setReportConfig(Object.assign(Object.assign({}, reportConfig), { embedUrl: token.embedUrl, accessToken: token.embedToken }));
            })
                .catch((error) => {
                console.error(error);
                setRetrievalError(true);
            });
        }
    }, [params.oid, params.rid]);
    return (_jsx(PageLayout, { title: reportTitle, hideOrgsDropdown: true, children: _jsx(ReportContent, { retrievalError: retrievalError, token: token, reportId: (_a = params.rid) !== null && _a !== void 0 ? _a : '', embedConfig: reportConfig, onReportLoaded: (report) => {
                setReportTitle(`Reports - ${report.appContext}`);
            } }) }));
};
