import { useEffect, useState } from 'react';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../objects/UserData';
export const useOrgUserRole = (user, orgId) => {
    const [role, setRole] = useState(OrganizationRoleEnum.NONE.toString());
    useEffect(() => {
        var _a, _b, _c;
        if (user && orgId) {
            if (user.systemRole === SystemRoleEnum.SUPER_ADMIN ||
                user.systemRole === SystemRoleEnum.ORG_APPROVER) {
                setRole(OrganizationRoleEnum.LOCAL_ADMIN);
            }
            else {
                const userRole = (_c = (_b = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationId === orgId)) === null || _b === void 0 ? void 0 : _b.role) !== null && _c !== void 0 ? _c : OrganizationRoleEnum.NONE.toString();
                setRole(userRole);
            }
        }
        else if (user &&
            (user.systemRole === SystemRoleEnum.SUPER_ADMIN ||
                user.systemRole === SystemRoleEnum.ORG_APPROVER)) {
            setRole(OrganizationRoleEnum.LOCAL_ADMIN);
        }
        else {
            setRole(OrganizationRoleEnum.NONE.toString());
        }
    }, [user, orgId]);
    return role;
};
