var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SystemRoleInput } from '../../../components/forms/SystemRoleInput';
import { TextInput } from '../../../components/forms/TextInput';
import { appColors } from '../../../config/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { SystemRoleEnum, } from '../../../objects/UserData';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
import { toSysRoleString } from '../../../utils/userRoleUtils';
export const EditAdminModal = ({ currentUserRole, adminToEdit, isOpen, onClose, }) => {
    const [roleId, setRoleId] = useState(undefined);
    const [roleMissing, setRoleMissing] = useState(false);
    const { configureUser } = usePlatformUserApi();
    useEffect(() => {
        if (adminToEdit && adminToEdit.systemRole) {
            setRoleId(adminToEdit.systemRole);
        }
    }, [adminToEdit]);
    const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();
    const toast = useToast();
    const firstNameRegister = register(FIRST_NAME_KEY, { required: true });
    const lastNameRegister = register(LAST_NAME_KEY, { required: true });
    const jobTitleRegister = register(JOB_TITLE_KEY, { required: true });
    const emailRegister = register(EMAIL_KEY, {
        pattern: /^\S+@\S+$/i,
        required: true,
    });
    const phoneNumberRegister = register(PHONE_NUMBER_KEY);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (adminToEdit) {
            setValue(FIRST_NAME_KEY, (_a = adminToEdit.firstName) !== null && _a !== void 0 ? _a : '');
            setValue(LAST_NAME_KEY, (_b = adminToEdit.lastName) !== null && _b !== void 0 ? _b : '');
            setValue(JOB_TITLE_KEY, (_c = adminToEdit.jobTitle) !== null && _c !== void 0 ? _c : '');
            setValue(EMAIL_KEY, (_d = adminToEdit.email) !== null && _d !== void 0 ? _d : '');
            setValue(PHONE_NUMBER_KEY, (_e = adminToEdit.phoneNumber) !== null && _e !== void 0 ? _e : '');
        }
    }, [setValue, adminToEdit]);
    const renderError = (label) => {
        if (label === 'First Name' && errors.firstName) {
            return `Please enter ${label}.`;
        }
        if (label === 'Last Name' && errors.lastName) {
            return `Please enter ${label}.`;
        }
        if (label === 'Title' && errors.jobTitle) {
            return `Please enter ${label}.`;
        }
        if (label === 'Email' && errors.email) {
            return `Please enter ${label}.`;
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (adminToEdit && adminToEdit.userIdentity) {
            if (roleId !== undefined) {
                try {
                    yield configureUser({
                        userId: adminToEdit.userIdentity,
                        systemRole: roleId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phoneNumber: data.phoneNumber,
                        jobTitle: data.jobTitle,
                    });
                    toast({
                        title: 'Saved',
                        status: 'success',
                        isClosable: true,
                    });
                    onEnd();
                }
                catch (_a) {
                    toast({
                        title: 'Save failed',
                        description: 'Please check that all fields are entered correctly.',
                        status: 'error',
                        isClosable: true,
                    });
                }
            }
        }
    });
    const determineRoleOptions = (currentUserRole, userToEditRole) => {
        if (userToEditRole === SystemRoleEnum.SUPER_ADMIN) {
            return [SystemRoleEnum.SUPER_ADMIN];
        }
        switch (currentUserRole) {
            case SystemRoleEnum.SUPER_ADMIN:
                return [
                    SystemRoleEnum.SUPER_ADMIN,
                    SystemRoleEnum.ORG_APPROVER,
                    SystemRoleEnum.USER,
                ];
            case SystemRoleEnum.ORG_APPROVER:
                return [SystemRoleEnum.ORG_APPROVER, SystemRoleEnum.USER];
            default:
                return [];
        }
    };
    const runReset = () => {
        reset({
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            phoneNumber: '',
        });
    };
    const onEnd = () => {
        runReset();
        onClose();
    };
    const canEditUserRole = (currentUserRole, userRoleToEdit) => {
        if (currentUserRole && userRoleToEdit) {
            return (currentUserRole === SystemRoleEnum.SUPER_ADMIN &&
                userRoleToEdit !== SystemRoleEnum.SUPER_ADMIN);
        }
        else {
            return false;
        }
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onEnd, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent
            // TODO: FIX SPACING
            , { 
                // TODO: FIX SPACING
                minW: 741, h: 503, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "Edit Admin" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: _jsxs(Stack, { py: 2, spacing: 5, children: [_jsx(SystemRoleInput, { label: 'Role', required: true, defaultValue: adminToEdit ? adminToEdit.systemRole : '', errorMsg: roleMissing ? 'Please select a Role.' : '', setRoleId: setRoleId, disabled: !canEditUserRole(currentUserRole, adminToEdit === null || adminToEdit === void 0 ? void 0 : adminToEdit.systemRole), children: _jsx(_Fragment, { children: determineRoleOptions(currentUserRole, adminToEdit === null || adminToEdit === void 0 ? void 0 : adminToEdit.systemRole).map((role, i) => {
                                                return (_jsx("option", { value: role, children: toSysRoleString(role) }, i));
                                            }) }) }), _jsx(TextInput, { label: 'First Name', register: firstNameRegister, required: true, errorMsg: renderError('First Name') }), _jsx(TextInput, { label: 'Last Name', register: lastNameRegister, required: true, errorMsg: renderError('Last Name') }), _jsx(TextInput, { label: 'Title', register: jobTitleRegister, required: true, errorMsg: renderError('Title') }), _jsx(TextInput, { label: 'Email', register: emailRegister, required: true, inputType: 'email', errorMsg: renderError('Email'), disabled: true }), _jsx(TextInput, { label: 'Phone', register: phoneNumberRegister, inputType: 'tel' })] }) }), _jsx(ModalFooter, { pt: 5, children: _jsx(Button, { type: 'submit', w: '100%', variant: variants.gradientBtn, onClick: () => {
                                    if (roleId === undefined) {
                                        setRoleMissing(true);
                                    }
                                }, children: "save changes" }) })] }) })] }));
};
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';
const JOB_TITLE_KEY = 'jobTitle';
const EMAIL_KEY = 'email';
const PHONE_NUMBER_KEY = 'phoneNumber';
