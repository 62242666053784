import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Exclude } from '../config/icons';
import { Paths } from '../config/paths';
import { variants } from '@frontend/design-system/theme/theme';
const InvitationErrorModal = ({ isOpen, onClose, }) => {
    const navigate = useNavigate();
    const handleContinue = () => {
        onClose();
        navigate(Paths.HOME);
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, { closeOnOverlayClick: false, isOpen: isOpen, onClose: onClose, trapFocus: false, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { maxWidth: '535px', p: '40px', borderRadius: '15px', m: 'auto', children: [_jsxs(Flex, { alignItems: 'center', mb: '40px', children: [_jsx(Exclude, { mr: '14px', w: '26px', h: '26px' }), _jsx(ModalHeader, { p: '0px', textAlign: 'center', fontFamily: 'Montserrat', fontSize: '32px', fontWeight: 400, letterSpacing: '1.5px', color: '#2E3532', whiteSpace: 'nowrap', children: "Something went wrong" })] }), _jsx(ModalBody, { p: '0px', mb: '40px', children: _jsxs(Text, { color: '#003B5D', fontSize: '14px', children: ["We\u2019re sorry, but we couldn\u2019t process your invitation. Please try again later or contact", ' ', _jsx(Link, { style: { textDecoration: 'underline' }, href: 'mailto:MysiteIQ_Info@sitelogiq.com', children: "MysiteIQ_Info@sitelogiq.com" }), "."] }) }), _jsx(ModalFooter, { p: '0px', children: _jsx(Button, { variant: variants.addNewBtn, onClick: handleContinue, fontSize: '14px', fontWeight: '500px', w: '106px', children: "Continue" }) })] })] }) }));
};
export default InvitationErrorModal;
