import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Text, VStack } from '@chakra-ui/react';
import { PageLayout } from '../components/layouts/PageLayout';
import { useAppSelector } from '../store/hooks';
import { memoizedSelectOrganization } from '../store/orgSlice';
import { ClientConciergeText } from './ClientConciergeText';
export const AccessDenied = () => {
    const { org: currentOrg } = useAppSelector(memoizedSelectOrganization);
    return (_jsx(PageLayout, { children: _jsx(Center, { h: '85vh', w: '100%', children: _jsxs(VStack, { children: [_jsx(Text, { children: "At this moment, you do not have access." }), (currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.clientConcierge) ? (_jsx(ClientConciergeText, { concierge: currentOrg.clientConcierge })) : (_jsx(Text, { children: "Please contact your client concierge" }))] }) }) }));
};
