var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../../config/constants';
import { updateFacilityAddition } from '../../../../services/api/orgDetailsAPI';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { fetchPlatformSitesByOrgId, memoizedSelectOrganization, } from '../../../../store/orgSlice';
export const EditFacilityAdditionModal = ({ facilityAdditionToEdit, isOpen, onClose, }) => {
    const { org: orgCurrent } = useAppSelector(memoizedSelectOrganization);
    const { register, handleSubmit, setValue, formState: { errors }, reset, } = useForm();
    const dispatch = useAppDispatch();
    const toast = useToast();
    const additionNameRegister = register(ADDITION_NAME_KEY, {
        required: true,
    });
    const additionSquareFootageRegister = register(ADDITION_SQUARE_FOOTAGE_KEY, {
        required: true,
    });
    const facilityYearRegister = register(FACILITY_YEAR_KEY, {
        required: true,
    });
    useEffect(() => {
        if (facilityAdditionToEdit.name &&
            facilityAdditionToEdit.squareFootage &&
            facilityAdditionToEdit.yearBuilt) {
            setValue(ADDITION_NAME_KEY, facilityAdditionToEdit.name);
            setValue(ADDITION_SQUARE_FOOTAGE_KEY, facilityAdditionToEdit.squareFootage);
            setValue(FACILITY_YEAR_KEY, facilityAdditionToEdit.yearBuilt);
        }
    }, [setValue, facilityAdditionToEdit]);
    const renderInput = (label, register, disabled, inputType) => {
        return (_jsxs(FormControl, { children: [_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: label }), _jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" })] }), _jsx(Input, Object.assign({ type: inputType ? inputType : 'text', placeholder: label, isDisabled: disabled }, register)), renderError(label)] }));
    };
    const renderError = (label) => {
        if (label === 'Year of construction' && errors.facilityYear) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'Addition' && errors.additionName) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        if (label === 'Addition square footage' &&
            errors.additionSquareFootage) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        let facilityYearTemp = 0;
        if (data.facilityYear) {
            facilityYearTemp = data.facilityYear;
        }
        let additionSquareFootageTemp = 0;
        if (data.additionSquareFootage) {
            additionSquareFootageTemp = data.additionSquareFootage;
        }
        try {
            if (facilityAdditionToEdit.additionId)
                yield updateFacilityAddition(facilityAdditionToEdit.additionId, {
                    facilityYear: facilityYearTemp,
                    additionName: data.additionName,
                    additionSquareFootage: additionSquareFootageTemp,
                });
            toast({
                title: 'Saved',
                status: 'success',
                isClosable: true,
            });
            orgCurrent &&
                dispatch(fetchPlatformSitesByOrgId(orgCurrent.orgIdentity));
            onClose();
            runReset();
        }
        catch (_a) {
            toast({
                title: 'Save failed',
                description: 'Please check that all fields are entered correctly.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const runReset = () => {
        reset({
            facilityYear: '',
            additionName: '',
            additionSquareFootage: '',
        });
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
            runReset();
        }, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent
            // TODO: FIX SPACING
            , { 
                // TODO: FIX SPACING
                minW: 741, h: 503, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "New Facility" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: _jsxs(Stack, { py: 2, spacing: 5, children: [renderInput('Year of construction', facilityYearRegister, false, 'number'), renderInput('Addition', additionNameRegister), renderInput('Addition square footage', additionSquareFootageRegister, false, 'number')] }) }), _jsx(ModalFooter, { pt: 5, children: _jsx(Button, { type: 'submit', w: '100%', variant: variants.gradientBtn, children: "save changes" }) })] }) })] }));
};
const ADDITION_NAME_KEY = 'additionName';
const ADDITION_SQUARE_FOOTAGE_KEY = 'additionSquareFootage';
const FACILITY_YEAR_KEY = 'facilityYear';
