import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FormControl, FormLabel, Heading, Image, Spacer, Switch, } from '@chakra-ui/react';
import { IAppNames } from '@frontend/domain/models/App';
import { Fragment } from 'react';
import { getAppNameAndLogo } from '../../../config/helperFunctions';
import { OrganizationRoleEnum, } from '../../../objects/UserData';
import { AddFacilityRolesToUser } from './AddFacilityRolesToUser';
const AddAppsToUser = ({ org, userOrgRole, orgUserApps, onOrgUserAppsChanged, selectedOrgFacilities, selectedOrgFacilityIds, currentUserFacilityRoles, updateFacRoles, }) => {
    var _a;
    const appCheckboxStates = ((_a = org.apps) === null || _a === void 0 ? void 0 : _a.map((orgApp) => ({
        app: orgApp.app,
        checked: !!orgUserApps.find((orgUserApp) => orgApp.app === orgUserApp.app && !orgUserApp.unassign),
    }))) || [];
    const handleAppCheckbox = (app, checked) => {
        const newCheckboxStates = appCheckboxStates.map((checkboxState) => {
            if (checkboxState.app === app) {
                return { app, checked };
            }
            else {
                return checkboxState;
            }
        });
        onOrgUserAppsChanged(newCheckboxStates.map((checkboxState) => ({
            organizationId: org.id,
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            organizationIdentity: org.orgIdentity,
            app: checkboxState.app,
            unassign: !checkboxState.checked,
        })));
    };
    const renderSwitch = (app, checked) => {
        const { icon, name } = getAppNameAndLogo(app);
        if (!icon || !name) {
            return _jsx(_Fragment, {});
        }
        const showFacilityRoles = name === IAppNames.FCA &&
            checked &&
            userOrgRole !== OrganizationRoleEnum.LOCAL_ADMIN &&
            userOrgRole !== OrganizationRoleEnum.CLIENT_CONCIERGE &&
            selectedOrgFacilities &&
            selectedOrgFacilityIds;
        return (_jsxs(Fragment, { children: [_jsxs(Flex, { align: 'center', mt: '10px', children: [_jsxs(Flex, { align: 'center', children: [_jsx(Image, { src: icon, mr: '5px', boxSize: '30px' }), _jsx(Heading, { size: 'sm', children: name === IAppNames.PLANNER
                                        ? `Capital ${IAppNames.PLANNER}`
                                        : name })] }), _jsx(Spacer, {}), _jsx(Flex, { align: 'center', children: _jsx(Switch, { colorScheme: 'orange', size: 'md', isChecked: checked, onChange: (e) => handleAppCheckbox(app, e.target.checked) }) })] }, `${org.id}-${app}`), showFacilityRoles && (_jsx(AddFacilityRolesToUser, { selectedOrgFacilities: selectedOrgFacilities, currentUserFacilityRoles: currentUserFacilityRoles.filter((facilityUser) => selectedOrgFacilityIds === null || selectedOrgFacilityIds === void 0 ? void 0 : selectedOrgFacilityIds.has(facilityUser.facilityIdentity)), updateFacRoles: updateFacRoles }))] }, `${app} switch`));
    };
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Apps Access" }), appCheckboxStates.map(({ app, checked }) => renderSwitch(app, checked))] }));
};
export default AddAppsToUser;
