import { jsx as _jsx } from "react/jsx-runtime";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AccessDenied } from '../AccessDenied';
import { Flex, Spinner } from '@chakra-ui/react';
export const AuthenticationGuard = ({ component, access = true, noAccessComponent = () => _jsx(AccessDenied, {}), }) => {
    const Component = withAuthenticationRequired(access ? component : noAccessComponent, {
        onRedirecting: () => (_jsx(Flex, { h: 'full', w: 'full', justifyContent: 'center', alignItems: 'center', children: _jsx(Spinner, {}) })),
    });
    return _jsx(Component, {});
};
