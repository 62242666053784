var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormControl, FormLabel, HStack, Stack, Text, useToast, } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { variants } from '@frontend/design-system/theme/theme';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { IAppNames } from '@frontend/domain/models/App';
import { useOrganizationService } from '@frontend/domain/services/OrganizationService';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorLabel } from '../../../components/forms/ErrorLabel';
import { FormDropdown } from '../../../components/forms/FormDropdown';
import { appColors, logoSupportedFormats } from '../../../config/constants';
import { Paths } from '../../../config/paths';
import { BusinessUnitOptions, OrganizationRoleEnum, SystemRoleEnum, VerticalMarketOptions, } from '../../../objects/UserData';
import { updateOrg } from '../../../services/api/orgDetailsAPI';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
import { getUserByEmail } from '../../../services/api/profilePageAPI';
import { uploadFileToBlob } from '../../../services/imageUpload';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectStates } from '../../../store/lookupsSlice';
import { fetchOrgById, memoizedSelectOrganization, selectOrgsEditMode, toggleOrgsCreateMode, toggleOrgsEditMode, } from '../../../store/orgSlice';
import { canEditOrg } from '../../../utils/userRoleUtils';
import { SaveNew } from '../../Profile/SaveNew';
import { InputWithPopOver } from '../Facilities/components/InputWithPopOver';
import { FormWithLabel } from './components/FormWithLabel';
import { LogoInput } from './LogoInput';
import { UsersAccess } from './UsersAccess';
export const GeneralInput = ({ orgParams, orgRole, orgUsers, systemRole, }) => {
    var _a;
    const navigate = useNavigate();
    const states = useAppSelector(selectStates);
    const { org } = useAppSelector(memoizedSelectOrganization);
    const editing = useAppSelector(selectOrgsEditMode);
    // feature flag toggle for user role editing
    const canEdit = canEditOrg(orgRole);
    const canEditAppContext = SystemRoleEnum.SUPER_ADMIN === systemRole ||
        SystemRoleEnum.ORG_APPROVER === systemRole;
    const editMode = editing && canEdit;
    const [addAdminOrConcierge, setAddAdminOrConcierge] = useState(false);
    const [newPrimaryContactIdIdentity, setNewPrimaryContactIdIdentity] = useState();
    const [orgAdmins, setOrgAdmins] = useState([]);
    const [clientConciergeOnNewOrg, setClientConciergeOnNewOrg] = useState(undefined);
    const [localAdminsOnNewOrg, setLocalAdminsOnNewOrg] = useState([]);
    const [primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail] = useState(undefined);
    const [imageError, setImageError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const VALID_EXTENSIONS_ERROR_MESSAGE = `Allowed logo types: ${logoSupportedFormats.join(', ')}.`;
    const validFileSize = 3 * 1024 * 1024; // 3MB
    const VALID_SIZE_ERROR_MESSAGE = `Max logo size: ${validFileSize / 1024 / 1024} MB.`;
    const dispatch = useAppDispatch();
    const { register, handleSubmit, setValue, watch, resetField, formState: { errors }, } = useForm();
    const { platformApi } = useDependencies();
    const { sendInvite, configureUser } = usePlatformUserApi();
    const { organization } = useOrganizationService({ platformApi });
    const toast = useToast();
    const logoSmallRef = useRef(null);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        let apps = undefined;
        if (canEditAppContext) {
            apps = [];
            if (data.reports && data.reports.app) {
                if (!data.reports.expiresOn) {
                    data.reports.expiresOn = undefined;
                }
                apps.push(data.reports);
            }
            if (data.fca && data.fca.app) {
                if (!data.fca.expiresOn) {
                    data.fca.expiresOn = undefined;
                }
                apps.push(data.fca);
            }
            if (data.planner && data.planner.app) {
                if (!data.planner.expiresOn) {
                    data.planner.expiresOn = undefined;
                }
                apps.push(data.planner);
            }
            if (data.piq && data.piq.app) {
                if (!data.piq.expiresOn) {
                    data.piq.expiresOn = undefined;
                }
                apps.push(data.piq);
            }
        }
        const primaryContactIdIdentitySplit = newPrimaryContactIdIdentity
            ? newPrimaryContactIdIdentity.split(',')
            : undefined;
        if (!addAdminOrConcierge) {
            const logoFile = (_c = (_b = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c.item(0);
            if (logoFile) {
                data.logoSmallName = logoFile.name;
                // TODO: need to handle so blobs aren't uploaded twice
                uploadFileToBlob(logoFile);
            }
            else if (org) {
                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                data.logoSmallName = getImageNameFromUrl(org.smallLogoUrl);
            }
            if (org) {
                try {
                    // TODO: when switching over org to use Platform api, create a method to unify the PlatformOrganization interface with our Organization interface
                    yield updateOrg((_d = org.id) !== null && _d !== void 0 ? _d : '', {
                        address: {
                            address1: data.address.address1,
                            city: data.address.city,
                            state: data.address.state,
                            zip: data.address.zip,
                        },
                        businessUnit: data.businessUnit,
                        verticalMarket: data.verticalMarket,
                        companyWebsite: data.companyWebsite,
                        name: data.nameOfOrg,
                        smallLogoName: data.logoSmallName,
                        largeLogoName: data.logoLargeName,
                        primaryContactId: primaryContactIdIdentitySplit
                            ? primaryContactIdIdentitySplit[0]
                            : undefined,
                        primaryContactIdentity: primaryContactIdIdentitySplit
                            ? primaryContactIdIdentitySplit[1]
                            : undefined,
                        apps,
                    });
                    toast({
                        title: 'Saved',
                        status: 'success',
                        isClosable: true,
                    });
                    dispatch(fetchOrgById(org.id));
                    dispatch(toggleOrgsEditMode(false));
                }
                catch (error) {
                    if (org.primaryContact === null) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select primary contact.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else {
                        if (error instanceof AxiosError) {
                            toast({
                                title: 'Save failed',
                                description: (_e = error.response) === null || _e === void 0 ? void 0 : _e.data,
                                status: 'error',
                                duration: 10000,
                                isClosable: true,
                            });
                        }
                        else {
                            toast({
                                title: 'Save failed',
                                description: 'Please check that all fields are entered correctly.',
                                status: 'error',
                                isClosable: true,
                            });
                        }
                    }
                }
            }
            else {
                try {
                    if (!clientConciergeOnNewOrg) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Client Concierge.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else if (localAdminsOnNewOrg.length === 0) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Local Admin.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    else if (!primaryContactOnNewOrgEmail) {
                        toast({
                            title: 'Save failed',
                            description: 'Please select a Primary Contact.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                    if (clientConciergeOnNewOrg &&
                        localAdminsOnNewOrg.length > 0 &&
                        primaryContactOnNewOrgEmail) {
                        setIsLoading(true);
                        let blob = undefined;
                        if (((_f = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _f === void 0 ? void 0 : _f.files) &&
                            ((_h = (_g = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _g === void 0 ? void 0 : _g.files) === null || _h === void 0 ? void 0 : _h.length) > 0) {
                            blob = new Blob([logoSmallRef.current.files[0]], {
                                type: logoSmallRef.current.files[0].type,
                            });
                        }
                        const createdOrg = yield organization.update(data, blob, apps);
                        navigate(`${Paths.ORG_DETAILS}/${createdOrg.id}`);
                        let clientConciergeId = undefined;
                        let clientConciergeIdentity = undefined;
                        let primaryContactId = undefined;
                        let primaryContactIdentity = undefined;
                        if (clientConciergeOnNewOrg.isNew) {
                            yield sendInvite({
                                firstName: clientConciergeOnNewOrg.firstName,
                                lastName: clientConciergeOnNewOrg.lastName,
                                jobTitle: clientConciergeOnNewOrg.jobTitle,
                                phoneNumber: clientConciergeOnNewOrg.phoneNumber,
                                emailAddress: clientConciergeOnNewOrg.emailAddress,
                                systemRole: SystemRoleEnum.USER,
                                organizationRoles: [
                                    {
                                        organizationId: createdOrg.id,
                                        organizationIdentity: createdOrg.identity,
                                        role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                                        unassign: false,
                                    },
                                ],
                            });
                            const newUser = yield getUserByEmail(
                            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientConciergeOnNewOrg.emailAddress);
                            clientConciergeId = newUser.id;
                            clientConciergeIdentity = newUser.userIdentity;
                        }
                        else {
                            yield configureUser({
                                userId: clientConciergeOnNewOrg.userId,
                                systemRole: clientConciergeOnNewOrg.systemRole,
                                firstName: clientConciergeOnNewOrg.firstName,
                                lastName: clientConciergeOnNewOrg.lastName,
                                phoneNumber: clientConciergeOnNewOrg.phoneNumber,
                                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                jobTitle: clientConciergeOnNewOrg.jobTitle,
                                organizationRoles: [
                                    {
                                        organizationId: createdOrg.id,
                                        organizationIdentity: createdOrg.identity,
                                        role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                                        unassign: false,
                                    },
                                ],
                            });
                            const newUser = yield getUserByEmail(
                            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientConciergeOnNewOrg.emailAddress);
                            clientConciergeId = newUser.id;
                            clientConciergeIdentity = newUser.userIdentity;
                        }
                        for (let i = 0; i < localAdminsOnNewOrg.length; i++) {
                            if (localAdminsOnNewOrg[i].isNew) {
                                yield sendInvite({
                                    firstName: localAdminsOnNewOrg[i].firstName,
                                    lastName: localAdminsOnNewOrg[i].lastName,
                                    jobTitle: localAdminsOnNewOrg[i].jobTitle,
                                    phoneNumber: localAdminsOnNewOrg[i].phoneNumber,
                                    emailAddress: localAdminsOnNewOrg[i].emailAddress,
                                    systemRole: SystemRoleEnum.USER,
                                    organizationRoles: [
                                        {
                                            organizationId: createdOrg.id,
                                            organizationIdentity: createdOrg.identity,
                                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                                            unassign: false,
                                        },
                                    ],
                                });
                                if (localAdminsOnNewOrg[i].emailAddress ===
                                    primaryContactOnNewOrgEmail) {
                                    const newUser = yield getUserByEmail(
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    localAdminsOnNewOrg[i].emailAddress);
                                    primaryContactId = newUser.id;
                                    primaryContactIdentity =
                                        newUser.userIdentity;
                                }
                            }
                            else {
                                yield configureUser({
                                    userId: localAdminsOnNewOrg[i].userId,
                                    systemRole: localAdminsOnNewOrg[i].systemRole,
                                    firstName: localAdminsOnNewOrg[i].firstName,
                                    lastName: localAdminsOnNewOrg[i].lastName,
                                    phoneNumber: localAdminsOnNewOrg[i].phoneNumber,
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    jobTitle: localAdminsOnNewOrg[i].jobTitle,
                                    organizationRoles: [
                                        {
                                            organizationId: createdOrg.id,
                                            organizationIdentity: createdOrg.identity,
                                            role: OrganizationRoleEnum.LOCAL_ADMIN,
                                            unassign: false,
                                        },
                                    ],
                                });
                                if (localAdminsOnNewOrg[i].emailAddress ===
                                    primaryContactOnNewOrgEmail) {
                                    const newUser = yield getUserByEmail(
                                    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    localAdminsOnNewOrg[i].emailAddress);
                                    primaryContactId = newUser.id;
                                    primaryContactIdentity =
                                        newUser.userIdentity;
                                }
                            }
                        }
                        yield updateOrg(createdOrg.id, {
                            clientConciergeId: clientConciergeId,
                            clientConciergeIdentity: clientConciergeIdentity,
                            primaryContactId: primaryContactId,
                            primaryContactIdentity: primaryContactIdentity,
                            name: data.nameOfOrg,
                            businessUnit: data.businessUnit,
                            address: {
                                address1: data.address.address1,
                                city: data.address.city,
                                state: data.address.state,
                                zip: data.address.zip,
                            },
                            companyWebsite: data.companyWebsite,
                            verticalMarket: data.verticalMarket,
                        });
                        dispatch(fetchOrgById(createdOrg.id));
                        toast({
                            title: 'Saved',
                            status: 'success',
                            isClosable: true,
                        });
                        setClientConciergeOnNewOrg(undefined);
                        setLocalAdminsOnNewOrg([]);
                        setPrimaryContactOnNewOrgEmail(undefined);
                        dispatch(toggleOrgsCreateMode(false));
                    }
                }
                catch (error) {
                    if (error instanceof AxiosError) {
                        toast({
                            title: 'Save failed',
                            description: (_j = error.response) === null || _j === void 0 ? void 0 : _j.data,
                            status: 'error',
                            duration: 10000,
                            isClosable: true,
                        });
                    }
                    else {
                        toast({
                            title: 'Save failed',
                            description: 'Please check that all fields are entered correctly.',
                            status: 'error',
                            isClosable: true,
                        });
                    }
                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    });
    const getImageNameFromUrl = (url) => {
        if (!url) {
            return '';
        }
        // TODO: make env specific and consolidate buckets
        const urlArray = url.split('https://mysiteiqstorage.blob.core.windows.net/dev-images/');
        return urlArray[1];
    };
    const nameOfOrgRegister = register(NAME_OF_ORG_KEY, { required: true });
    const businessUnitRegister = register(BUSINESS_UNIT_KEY, {
        required: true,
    });
    const verticalMarketRegister = register(VERTICAL_MARKET_KEY, {
        required: true,
    });
    const streetAddressRegister = register(STREET_ADDRESS_KEY, {
        required: true,
    });
    const cityRegister = register(CITY_KEY, { required: true });
    const stateRegister = register(STATE_KEY, { required: true });
    const zipCodeRegister = register(ZIP_CODE_KEY, { required: true });
    const companyWebsiteRegister = register(COMPANY_WEBSITE_KEY, {
        required: true,
    });
    // TODO:  refactor how we are setting this page to edit mode (don't determine by params, and move away from Redux.)
    useEffect(() => {
        if (!orgParams) {
            dispatch(toggleOrgsEditMode(true));
            setClientConciergeOnNewOrg(undefined);
            setLocalAdminsOnNewOrg([]);
            setPrimaryContactOnNewOrgEmail(undefined);
        }
    }, []);
    useEffect(() => {
        if (orgUsers && org) {
            let localAdmins = [];
            orgUsers.forEach((user) => {
                var _a;
                const orgRole = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationId.toUpperCase() === org.id);
                if (orgRole &&
                    orgRole.role === OrganizationRoleEnum.LOCAL_ADMIN) {
                    localAdmins = [...localAdmins, user];
                }
            });
            setOrgAdmins(localAdmins);
        }
    }, [orgUsers, org]);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (org) {
            setValue(NAME_OF_ORG_KEY, org.name);
            setValue(BUSINESS_UNIT_KEY, org.businessUnit);
            setValue(VERTICAL_MARKET_KEY, org.verticalMarket);
            setValue(COMPANY_WEBSITE_KEY, org.companyWebsite);
            setValue(STREET_ADDRESS_KEY, org.address.address1);
            setValue(CITY_KEY, org.address.city);
            setValue(STATE_KEY, org.address.state);
            setValue(ZIP_CODE_KEY, org.address.zip);
            setNewPrimaryContactIdIdentity(`${org.primaryContactId},${org.primaryContactIdentity}`);
            const reports = (_a = org.apps) === null || _a === void 0 ? void 0 : _a.find((app) => IAppNames.REPORTS === app.app);
            const planner = (_b = org.apps) === null || _b === void 0 ? void 0 : _b.find((app) => IAppNames.PLANNER === app.app);
            const fca = (_c = org.apps) === null || _c === void 0 ? void 0 : _c.find((app) => IAppNames.FCA === app.app);
            const piq = (_d = org.apps) === null || _d === void 0 ? void 0 : _d.find((app) => IAppNames.PIQ === app.app);
            if (reports) {
                setValue(REPORTS_KEY, reports);
            }
            if (planner) {
                setValue(PLANNER_KEY, planner);
            }
            if (fca) {
                setValue(FCA_KEY, fca);
            }
            if (piq) {
                setValue(PIQ_KEY, piq);
            }
        }
    }, [setValue, org]);
    const requiredFields = [
        'Name of the organization',
        'Business Unit',
        'Company website',
        'Vertical Market',
        'City',
        'State',
        'Street Address',
        'Zip Code',
    ];
    const renderError = (register, label) => {
        if (errors[register.name] ||
            (register.name.includes('address') &&
                errors.address &&
                errors.address[register.name.split('.')[1]])) {
            return _jsx(ErrorLabel, { label: label });
        }
        else {
            return _jsx("p", { style: { visibility: 'hidden' }, children: "Valid input format" });
        }
    };
    const renderText = (label) => {
        switch (label) {
            case 'Name of the organization':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', "data-testid": 'orgName', children: org === null || org === void 0 ? void 0 : org.name }) }));
            case 'Company website':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.companyWebsite }) }));
            case 'Client concierge':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: clientConciergeOnNewOrg
                            ? `${clientConciergeOnNewOrg.firstName} ${clientConciergeOnNewOrg.lastName}`
                            : (org === null || org === void 0 ? void 0 : org.clientConciergeId) &&
                                `${org === null || org === void 0 ? void 0 : org.clientConcierge.firstName} ${org === null || org === void 0 ? void 0 : org.clientConcierge.lastName}` }) }));
            case 'Business Unit':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.businessUnit }) }));
            case 'Vertical Market':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.verticalMarket }) }));
            case 'Street Address':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.address.address1 }) }));
            case 'State':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.address.state }) }));
            case 'City':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.address.city }) }));
            case 'Zip Code':
                return (_jsx(FormWithLabel, { label: label, children: _jsx(Text, { paddingLeft: '20px', children: org === null || org === void 0 ? void 0 : org.address.zip }) }));
            default:
                return _jsx(_Fragment, {});
        }
    };
    const renderBasedOnMode = (readOnlyCmp, editableCmp) => (editMode ? editableCmp : readOnlyCmp);
    const handleImageErrorMessage = (file, supportedFormats) => {
        const fileExtension = file.name
            .substring(file.name.lastIndexOf('.'))
            .toLowerCase();
        if (!supportedFormats.includes(fileExtension)) {
            setImageError(VALID_EXTENSIONS_ERROR_MESSAGE);
            return;
        }
        else if (file.size > validFileSize) {
            setImageError(VALID_SIZE_ERROR_MESSAGE);
            return;
        }
        else {
            setImageError('');
            return;
        }
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(Stack, { w: '100%', spacing: -2, children: [_jsxs(HStack, { w: '100%', alignItems: 'flex-start', spacing: 4, children: [_jsxs(Stack, { w: '30%', spacing: '18px', children: [renderBasedOnMode(renderText('Name of the organization'), _jsx(InputWithPopOver, { label: 'Name of the organization', register: nameOfOrgRegister, required: editMode &&
                                            requiredFields.includes('Name of the organization'), renderError: renderError })), _jsx(Box, { pl: 5, children: _jsx(LogoInput, { logoUrl: (_a = org === null || org === void 0 ? void 0 : org.smallLogoUrl) !== null && _a !== void 0 ? _a : '', logoRef: logoSmallRef, imageError: imageError, handleImageErrorMessage: handleImageErrorMessage }) }), renderBasedOnMode(renderText('Business Unit'), _jsx(FormDropdown, { required: editMode &&
                                            requiredFields.includes('Business Unit'), options: BusinessUnitOptions, defaultValue: org === null || org === void 0 ? void 0 : org.businessUnit, label: 'Business Unit', optionKey: 'business-unit', register: businessUnitRegister, renderError: renderError })), renderBasedOnMode(renderText('Vertical Market'), _jsx(FormDropdown, { required: editMode &&
                                            requiredFields.includes('Vertical Market'), options: VerticalMarketOptions, defaultValue: org === null || org === void 0 ? void 0 : org.verticalMarket, label: 'Vertical Market', optionKey: 'vertical-market', register: verticalMarketRegister, renderError: renderError }))] }), _jsxs(Stack, { w: '23%', spacing: '18px', children: [renderBasedOnMode(renderText('Street Address'), _jsx(InputWithPopOver, { label: 'Street Address', register: streetAddressRegister, required: editMode &&
                                            requiredFields.includes('Street Address'), renderError: renderError })), renderBasedOnMode(renderText('City'), _jsx(InputWithPopOver, { label: 'City', register: cityRegister, required: editMode && requiredFields.includes('City'), renderError: renderError })), renderBasedOnMode(renderText('State'), _jsx(FormDropdown, { required: editMode && requiredFields.includes('State'), options: states.map((s) => s.description), defaultValue: org === null || org === void 0 ? void 0 : org.address.state, label: 'State', optionKey: 'state', register: stateRegister, renderError: renderError })), renderBasedOnMode(renderText('Zip Code'), _jsx(InputWithPopOver, { label: 'Zip Code', register: zipCodeRegister, required: editMode &&
                                            requiredFields.includes('Zip Code'), renderError: renderError })), renderBasedOnMode(renderText('Company website'), _jsx(InputWithPopOver, { label: 'Company website', register: companyWebsiteRegister, required: editMode &&
                                            requiredFields.includes('Company website'), renderError: renderError }))] }), _jsx(Stack, { w: '42%', spacing: '18px', children: _jsx(UsersAccess, { canEditAppContext: canEditAppContext, editMode: editMode, newPrimaryContactIdIdentity: newPrimaryContactIdIdentity, org: org, orgAdmins: orgAdmins, register: register, renderText: renderText, requiredFields: requiredFields, resetField: resetField, setAddAdminOrConcierge: setAddAdminOrConcierge, setNewPrimaryContactIdIdentity: setNewPrimaryContactIdIdentity, setValue: setValue, watch: watch, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg, primaryContactOnNewOrgEmail: primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail: setPrimaryContactOnNewOrgEmail }) })] }), _jsxs(Box, { mt: '20px !important', children: [editMode && (_jsxs(FormControl, { isRequired: true, mt: '5px', mb: '25px', ml: '-18px', children: [' ', _jsxs(FormLabel, { fontSize: '14', color: appColors.TEXT_COLOR, children: ["Required field marked with", ' '] })] })), canEdit && (_jsxs(HStack, { mb: '20px', children: [org && (_jsx(Button, { variant: variants.editBtn, onClick: () => {
                                            dispatch(toggleOrgsEditMode(!editMode));
                                            setImageError('');
                                            // reset the input value to allow selecting the same file again
                                            if (logoSmallRef.current) {
                                                logoSmallRef.current.value = '';
                                            }
                                        }, children: !editMode ? 'Edit' : 'Cancel' })), _jsx(SaveNew, { org: org, editMode: editMode, isSubmitting: isLoading || imageError ? true : false })] }))] })] }), _jsx(LoadingWithControl, { isLoading: isLoading })] }));
};
const NAME_OF_ORG_KEY = 'nameOfOrg';
const BUSINESS_UNIT_KEY = 'businessUnit';
const VERTICAL_MARKET_KEY = 'verticalMarket';
const STREET_ADDRESS_KEY = 'address.address1';
const CITY_KEY = 'address.city';
const STATE_KEY = 'address.state';
const ZIP_CODE_KEY = 'address.zip';
const COMPANY_WEBSITE_KEY = 'companyWebsite';
const REPORTS_KEY = 'reports';
const PLANNER_KEY = 'planner';
const FCA_KEY = 'fca';
const PIQ_KEY = 'piq';
