var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformOrgApi } from '@/services/api/platformOrgApiHook';
import { createAsyncThunk, createSelector, createSlice, } from '@reduxjs/toolkit';
import { getAllOrgs, getOrg, getOrgById, updateOrg, } from '../services/api/orgDetailsAPI';
const initialOrganization = undefined;
export const OrgRelations = {
    sites: 'sites',
    sitesFacilities: 'sites.facilities',
    sitesFacilitiesAdditions: 'sites.facilities.facilityAdditions',
    primaryContact: 'primaryContact',
    clientConcierge: 'clientConcierge',
    apps: 'apps',
};
const FETCH_ORG_RELATIONS = [
    'primaryContact',
    'clientConcierge',
    'apps',
];
export const fetchOrg = createAsyncThunk('organizations/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getOrg();
})); // deprecated?
export const fetchOrganizationSnapshots = createAsyncThunk('organizations-snapshots/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    const { getOrganizationSnapshots } = usePlatformOrgApi();
    const orgs = yield getOrganizationSnapshots();
    const sortedOrgs = orgs.sort((a, b) => a.name.localeCompare(b.name));
    return sortedOrgs;
}));
export const fetchPlatformSitesByOrgId = createAsyncThunk('organizations/fetchById', (id) => __awaiter(void 0, void 0, void 0, function* () {
    const { getPlatformSitesByOrgId } = usePlatformOrgApi();
    return yield getPlatformSitesByOrgId(id);
}));
export const fetchAllOrgs = createAsyncThunk('organizations/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getAllOrgs();
}));
export const fetchAllUserOrgs = createAsyncThunk('user/organizations/fetch', (orgRoles) => __awaiter(void 0, void 0, void 0, function* () {
    const orgs = yield getAllOrgs();
    return orgs.filter((org) => orgRoles.find((orgRole) => orgRole.organizationIdentity === org.orgIdentity));
}));
export const fetchOrgById = createAsyncThunk('organizations/id/fetch', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getOrgById(id, FETCH_ORG_RELATIONS);
}));
export const editOrg = createAsyncThunk('users/editOrg', ({ id, org }) => __awaiter(void 0, void 0, void 0, function* () {
    return updateOrg(id, org);
}));
export const initialState = {
    authOrg: null,
    organization: initialOrganization,
    platformSites: undefined,
    allOrgs: [],
    allOrgsSnapshots: [],
    userOrgs: [],
    inOrgsEditMode: false,
    inOrgsCreateMode: false,
    loadingSpinner: false,
    orgLoading: false,
    platformSitesLoading: false,
};
export const orgSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setOrganization: (state, { payload: userData }) => {
            state.organization = userData;
        },
        toggleOrgsEditMode: (state, { payload: inOrgsEditMode }) => {
            state.inOrgsEditMode = inOrgsEditMode;
        },
        toggleOrgsCreateMode: (state, { payload: inOrgsCreateMode }) => {
            state.inOrgsCreateMode = inOrgsCreateMode;
        },
        setLoadingSpinner: (state, { payload: loading }) => {
            state.loadingSpinner = loading;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizationSnapshots.fulfilled, (state, action) => {
            state.allOrgsSnapshots = action.payload;
        });
        builder.addCase(fetchAllOrgs.fulfilled, (state, action) => {
            state.allOrgs = action.payload;
        });
        builder.addCase(fetchAllUserOrgs.fulfilled, (state, action) => {
            state.userOrgs = action.payload;
            state.allOrgsSnapshots = action.payload.map((o) => ({
                id: o.id,
                identity: o.orgIdentity,
                name: o.name,
                apps: o.apps,
            }));
        });
        builder.addCase(fetchOrgById.fulfilled, (state, action) => {
            state.organization = action.payload;
            state.orgLoading = false;
        });
        builder.addCase(fetchOrgById.pending, (state) => {
            state.orgLoading = true;
        });
        builder.addCase(fetchOrgById.rejected, (state) => {
            state.orgLoading = false;
        });
        builder.addCase(fetchPlatformSitesByOrgId.fulfilled, (state, action) => {
            state.platformSites = action.payload;
            state.platformSitesLoading = false;
        });
        builder.addCase(fetchPlatformSitesByOrgId.pending, (state) => {
            state.platformSitesLoading = true;
        });
        builder.addCase(fetchPlatformSitesByOrgId.rejected, (state) => {
            state.platformSitesLoading = false;
        });
    },
});
export const selectOrgsEditMode = (state) => state.organization.inOrgsEditMode;
export const selectOrgsCreateMode = (state) => state.organization.inOrgsCreateMode;
export const selectOrganization = (state) => {
    return {
        org: state.organization.organization,
        loading: state.organization.orgLoading,
    };
};
export const memoizedSelectOrganization = createSelector([selectOrganization], (org) => org);
export const selectPlatformSites = (state) => {
    return {
        sites: state.organization.platformSites,
        loading: state.organization.platformSitesLoading,
    };
};
export const memoizedSelectPlatformSites = createSelector([selectPlatformSites], (sites) => sites);
export const selectAllOrgsSnapshots = (state) => state.organization.allOrgsSnapshots;
export const selectAllOrgs = (state) => state.organization.allOrgs;
export const selectUserOrgs = (state) => state.organization.userOrgs;
export const selectLoadingSpinner = (state) => state.organization.loadingSpinner;
export const { setOrganization, toggleOrgsEditMode, toggleOrgsCreateMode, setLoadingSpinner, } = orgSlice.actions;
export default orgSlice.reducer;
