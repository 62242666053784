import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Td, Tooltip, Tr, } from '@chakra-ui/react';
import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { appColors } from '../../../config/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { UserOrganizationTable } from '../UserManagement/UserOrganizationTable';
export const SystemAdminRow = ({ children, popoverMenu, user, orgs, }) => {
    const [showOrganizations, setShowOrganizations] = useState(false);
    const renderActionCol = () => {
        return (_jsxs(Td, { w: '10em', children: [_jsxs(Popover, { placement: 'left', children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { boxSize: '25px', icon: _jsx(BsThreeDots, {}), "aria-label": 'More Menu', variant: 'ghost' }) }), _jsx(PopoverContent, { width: 'auto', border: '1px solid #E6E7E9', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)', bg: appColors.BACKGROUND_LIGHT_GRAY, children: _jsx(PopoverBody, { children: popoverMenu }) })] }), user && (_jsx(Tooltip, { label: showOrganizations
                        ? 'Hide organizations'
                        : 'Show organizations', openDelay: 300, bg: 'brand.primBlue', children: _jsx(IconButton, { onClick: () => setShowOrganizations(!showOrganizations), boxSize: '26px', icon: showOrganizations ? (_jsx(ChevronUpIcon, {})) : (_jsx(ChevronDownIcon, {})), "aria-label": 'show facility additions', variant: variants.transparentIcoBtn }) }))] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Tr, { children: [children, renderActionCol()] }), showOrganizations && (_jsx(Tr, { children: _jsx(Td, { colSpan: 6, children: _jsx(UserOrganizationTable, { user: user, orgs: orgs }) }) }))] }));
};
