import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DependenciesProvider } from '@frontend/domain/contexts/Dependencies/DependenciesProvider';
import { ToastContainer } from '@frontend/domain/ToastContainer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { environment } from './config/environment';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Auth0ProviderWithNavigate } from './sharedComponents/auth/AuthProviderWithNavigate';
import { store } from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(DependenciesProvider, { mysiteApi: {
                baseUrl: environment.apis.mysiteIQAPI,
                token: '',
            }, platformApi: {
                baseUrl: environment.apis.platformAPI,
                token: '',
            }, children: _jsx(BrowserRouter, { children: _jsx(Auth0ProviderWithNavigate, { children: _jsxs(_Fragment, { children: [_jsx(App, {}), _jsx(ToastContainer, {})] }) }) }) }) }) }));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
