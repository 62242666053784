export class DefaultService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param groupIdentity
     * @returns AssetGroupSnapshotAssets Ok
     * @throws ApiError
     */
    getAssetsSnapshot(groupIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/snapshot/{groupIdentity}',
            path: {
                groupIdentity: groupIdentity,
            },
        });
    }
    /**
     * @param gid
     * @param amount
     * @returns Photo Ok
     * @throws ApiError
     */
    getPhotos(gid, amount) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/{gid}/photos',
            path: {
                gid: gid,
            },
            query: {
                amount: amount,
            },
        });
    }
    /**
     * @param gid
     * @returns string Ok
     * @throws ApiError
     */
    deleteOneGroup(gid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{gid}',
            path: {
                gid: gid,
            },
        });
    }
    /**
     * @param fid
     * @returns string Ok
     * @throws ApiError
     */
    deleteGroups(fid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/all/{fid}',
            path: {
                fid: fid,
            },
        });
    }
    /**
     * @param cid
     * @returns PhotoComment Ok
     * @throws ApiError
     */
    getPhotoComment(cid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/photoComments/{cid}',
            path: {
                cid: cid,
            },
        });
    }
    /**
     * @param cid
     * @returns string Ok
     * @throws ApiError
     */
    deletePhotoComment(cid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/photoComments/{cid}',
            path: {
                cid: cid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns PhotoComment Ok
     * @throws ApiError
     */
    createPhotoComment(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/photoComments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param cid
     * @param requestBody
     * @returns ContactData Ok
     * @throws ApiError
     */
    updateContact(cid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/contacts/{cid}',
            path: {
                cid: cid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param efid
     * @param requestBody
     * @returns EscalationFactor Ok
     * @throws ApiError
     */
    updateEscalationFactor(efid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/escalationFactors/{efid}',
            path: {
                efid: efid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param efid
     * @returns string Ok
     * @throws ApiError
     */
    deleteEscalationFactor(efid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/escalationFactors/{efid}',
            path: {
                efid: efid,
            },
        });
    }
    /**
     * @param orgIdentity
     * @returns EscalationFactor Ok
     * @throws ApiError
     */
    getAllByOrgId(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/escalationFactors',
            query: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param fidentity
     * @returns Facility Ok
     * @throws ApiError
     */
    getFacility(fidentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fidentity}',
            path: {
                fidentity: fidentity,
            },
        });
    }
    /**
     * @param orgIdentity
     * @returns Facility Ok
     * @throws ApiError
     */
    getFacilitiesSpacesAssetsByOrg(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/organization/{orgIdentity}',
            path: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param fid
     * @returns Facility Ok
     * @throws ApiError
     */
    getCsv(fid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fid}/csv',
            path: {
                fid: fid,
            },
        });
    }
    /**
     * @param fidentity
     * @returns Plan Ok
     * @throws ApiError
     */
    getPlansForFacility(fidentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fidentity}/plans',
            path: {
                fidentity: fidentity,
            },
        });
    }
    /**
     * @param fidentity
     * @param requestBody
     * @returns Plan Ok
     * @throws ApiError
     */
    addPlan(fidentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fidentity}/plans',
            path: {
                fidentity: fidentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fid
     * @returns ProjectFacility Ok
     * @throws ApiError
     */
    getProjectEditData(fid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fid}/projectFacilitySnapshot',
            path: {
                fid: fid,
            },
        });
    }
    /**
     * @param fid
     * @returns FacilityAddition Ok
     * @throws ApiError
     */
    getAdditionsForFacility(fid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fid}/additions',
            path: {
                fid: fid,
            },
        });
    }
    /**
     * @param fidentity
     * @param requestBody
     * @returns FacilityAddition Ok
     * @throws ApiError
     */
    addAddition(fidentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fidentity}/additions',
            path: {
                fidentity: fidentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fid
     * @param requestBody
     * @returns Facility Ok
     * @throws ApiError
     */
    updateFacility(fid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fid}',
            path: {
                fid: fid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fid
     * @returns string Ok
     * @throws ApiError
     */
    deleteFacility(fid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/facilities/{fid}',
            path: {
                fid: fid,
            },
        });
    }
    /**
     * @param fid
     * @param requestBody
     * @returns Facility Ok
     * @throws ApiError
     */
    updateFacilityNote(fid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fid}/note',
            path: {
                fid: fid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fIdentity
     * @param requestBody
     * @returns FacilityEV Ok
     * @throws ApiError
     */
    updateFacilityEv(fIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fIdentity}/ev/update',
            path: {
                fIdentity: fIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fIdentity
     * @param requestBody
     * @returns FacilityLighting Ok
     * @throws ApiError
     */
    updateFacilityLighting(fIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilities/{fIdentity}/lighting/update',
            path: {
                fIdentity: fIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fIdentity
     * @returns FacilityLighting Ok
     * @throws ApiError
     */
    getFacilityLighting(fIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fIdentity}/lighting',
            path: {
                fIdentity: fIdentity,
            },
        });
    }
    /**
     * @param fidentity
     * @returns FacilityEV Ok
     * @throws ApiError
     */
    getFacilityEv(fidentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilities/{fidentity}/ev',
            path: {
                fidentity: fidentity,
            },
        });
    }
    /**
     * @param faid
     * @param requestBody
     * @returns FacilityAddition Ok
     * @throws ApiError
     */
    updateFacilityAddition(faid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilityAdditions/{faid}',
            path: {
                faid: faid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @returns FacilityGrouper Ok
     * @throws ApiError
     */
    getByOrgIdentity(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facilityGroupers',
            query: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param requestBody
     * @returns FacilityGrouper Ok
     * @throws ApiError
     */
    upsertFacilityGrouper(requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/facilityGroupers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns FeatureFlagsResponse Ok
     * @throws ApiError
     */
    getAllFeatureFlags() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features',
        });
    }
    /**
     * @param flag
     * @returns boolean Ok
     * @throws ApiError
     */
    getFeatureFlag(flag) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features/{flag}',
            path: {
                flag: flag,
            },
        });
    }
    /**
     * @param fid
     * @param requestBody
     * @returns FundingType Ok
     * @throws ApiError
     */
    updateFundingType(fid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/fundingTypes/{fid}',
            path: {
                fid: fid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @returns FundingType Ok
     * @throws ApiError
     */
    getAllByOrgId1(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fundingTypes',
            query: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @returns IFacilityType Ok
     * @throws ApiError
     */
    getAllVerticalMarkets() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lookups/verticalMarkets',
        });
    }
    /**
     * @returns IState Ok
     * @throws ApiError
     */
    getAllStates() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lookups/states',
        });
    }
    /**
     * @param oid
     * @param relations
     * @returns FullOrganization Ok
     * @throws ApiError
     */
    getOrganization(oid, relations) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{oid}',
            path: {
                oid: oid,
            },
            query: {
                relations: relations,
            },
        });
    }
    /**
     * @param oid
     * @returns string Ok
     * @throws ApiError
     */
    deleteOrgById(oid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations/{oid}',
            path: {
                oid: oid,
            },
        });
    }
    /**
     * @returns OrganizationSnapshot Ok
     * @throws ApiError
     */
    getOrganizationsSnapshots() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations-snapshots',
        });
    }
    /**
     * @param relations
     * @returns Organization Ok
     * @throws ApiError
     */
    getOrganizations(relations) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations',
            query: {
                relations: relations,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    createOrganization(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns UserData Ok
     * @throws ApiError
     */
    adduserToOrganization(id, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organization/{id}/users',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    updateOrganization(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organization/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param oid
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addSite(oid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{oid}/sites',
            path: {
                oid: oid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param oid
     * @returns Site Ok
     * @throws ApiError
     */
    getAllSitesForOrg(oid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{oid}/sites',
            path: {
                oid: oid,
            },
        });
    }
    /**
     * @param oid
     * @returns OrgApp Ok
     * @throws ApiError
     */
    getOrgApps(oid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{oid}/apps',
            path: {
                oid: oid,
            },
        });
    }
    /**
     * @param uid
     * @param oid
     * @returns string Ok
     * @throws ApiError
     */
    removeUserByIdFromOrg(uid, oid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations/{oid}/users/{uid}',
            path: {
                uid: uid,
                oid: oid,
            },
        });
    }
    /**
     * @param oid
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addContact(oid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{oid}/contacts',
            path: {
                oid: oid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addFundingType(orgIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{orgIdentity}/fundingTypes',
            path: {
                orgIdentity: orgIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addProjectType(orgIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{orgIdentity}/projectTypes',
            path: {
                orgIdentity: orgIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addPrioritizationWeight(orgIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{orgIdentity}/prioritizationWeights',
            path: {
                orgIdentity: orgIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param requestBody
     * @returns Organization Ok
     * @throws ApiError
     */
    addEscalationFactor(orgIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{orgIdentity}/escalationFactors',
            path: {
                orgIdentity: orgIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param requestBody
     * @returns PhotoTag Ok
     * @throws ApiError
     */
    addTag(orgIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organization/{orgIdentity}/phototags',
            path: {
                orgIdentity: orgIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param tagIdentity
     * @returns number Ok
     * @throws ApiError
     */
    deleteTag(tagIdentity) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organization/phototags/{tagIdentity}',
            path: {
                tagIdentity: tagIdentity,
            },
        });
    }
    /**
     * Return a list of all facilities & EV/Lighting info for an organization
     * @param orgIdentity organization identity
     * @returns string Promise of Facilities for CSV export
     * @throws ApiError
     */
    getFacilitiesWithPriorityByOrganization(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{orgIdentity}/facilities',
            path: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param orgIdentity
     * @param formData
     * @returns CsvError Ok
     * @throws ApiError
     */
    bulkUpdateFacilitiesFromCsv(orgIdentity, formData) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/organizations/{orgIdentity}/upload/facilities',
            path: {
                orgIdentity: orgIdentity,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param phid
     * @returns Photo Ok
     * @throws ApiError
     */
    getPhoto(phid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/photos/{phid}',
            path: {
                phid: phid,
            },
        });
    }
    /**
     * @param phid
     * @param requestBody
     * @returns Photo Ok
     * @throws ApiError
     */
    updatePhoto(phid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/photos/{phid}',
            path: {
                phid: phid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param phid
     * @returns string Ok
     * @throws ApiError
     */
    deletePhoto(phid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/photos/{phid}',
            path: {
                phid: phid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Photo Ok
     * @throws ApiError
     */
    createPhotos(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/photos/list',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param phid
     * @param requestBody
     * @returns Photo Ok
     * @throws ApiError
     */
    addComments(phid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/photos/{phid}/comments',
            path: {
                phid: phid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    movePhotos(requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/photos/move',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @param relations
     * @returns Photo Ok
     * @throws ApiError
     */
    getPhotosByOrg(orgIdentity, relations) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/photos/{orgIdentity}/organizationPhotos',
            path: {
                orgIdentity: orgIdentity,
            },
            query: {
                relations: relations,
            },
        });
    }
    /**
     * @param photoIdentity
     * @param tagIdentity
     * @returns Photo Ok
     * @throws ApiError
     */
    addTagToPhoto(photoIdentity, tagIdentity) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/photos/{photoIdentity}/tags/{tagIdentity}',
            path: {
                photoIdentity: photoIdentity,
                tagIdentity: tagIdentity,
            },
        });
    }
    /**
     * @param photoIdentity
     * @param tagIdentity
     * @returns Photo Ok
     * @throws ApiError
     */
    deletePhotoTag(photoIdentity, tagIdentity) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/photos/{photoIdentity}/tags/{tagIdentity}',
            path: {
                photoIdentity: photoIdentity,
                tagIdentity: tagIdentity,
            },
        });
    }
    /**
     * @param pid
     * @returns Plan Ok
     * @throws ApiError
     */
    getPlan(pid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/plans/{pid}',
            path: {
                pid: pid,
            },
        });
    }
    /**
     * @param pid
     * @param requestBody
     * @returns Plan Ok
     * @throws ApiError
     */
    updatePlan(pid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/plans/{pid}',
            path: {
                pid: pid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pid
     * @returns string Ok
     * @throws ApiError
     */
    deletePlan(pid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/plans/{pid}',
            path: {
                pid: pid,
            },
        });
    }
    /**
     * @param orgIdentity
     * @returns PrioritizationWeight Ok
     * @throws ApiError
     */
    getAllByOrgId2(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/prioritizationWeights',
            query: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param pid
     * @param requestBody
     * @returns PrioritizationWeight Ok
     * @throws ApiError
     */
    updatePrioritizationWeight(pid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/prioritizationWeights/{pid}',
            path: {
                pid: pid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param oid
     * @returns Project Ok
     * @throws ApiError
     */
    getProjects(oid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/org/{oid}',
            path: {
                oid: oid,
            },
        });
    }
    /**
     * @param prid
     * @returns Project Ok
     * @throws ApiError
     */
    getProject(prid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/{prid}',
            path: {
                prid: prid,
            },
        });
    }
    /**
     * @param prid
     * @param requestBody
     * @returns Project Ok
     * @throws ApiError
     */
    updateProject(prid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/projects/{prid}',
            path: {
                prid: prid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param prid
     * @returns string Ok
     * @throws ApiError
     */
    deleteProject(prid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/projects/{prid}',
            path: {
                prid: prid,
            },
        });
    }
    /**
     * @param oid
     * @returns ProjectSite Ok
     * @throws ApiError
     */
    getProjectData(oid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/data/{oid}',
            path: {
                oid: oid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Project Ok
     * @throws ApiError
     */
    createProject(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param prid
     * @param requestBody
     * @returns Project Ok
     * @throws ApiError
     */
    updatePriorities(prid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/projects/{prid}/updatePriorities',
            path: {
                prid: prid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param prIdentity
     * @param requestBody
     * @returns Project Ok
     * @throws ApiError
     */
    addProjectFiles(prIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/projects/{prIdentity}/files',
            path: {
                prIdentity: prIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param prIdentity
     * @param amount
     * @returns Photo Ok
     * @throws ApiError
     */
    getProjectPhotos(prIdentity, amount) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/{prIdentity}/photos',
            path: {
                prIdentity: prIdentity,
            },
            query: {
                amount: amount,
            },
        });
    }
    /**
     * @param pfid
     * @returns string Ok
     * @throws ApiError
     */
    deleteProjectFile(pfid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/files/{pfid}',
            path: {
                pfid: pfid,
            },
        });
    }
    /**
     * @param orgIdentity
     * @returns ProjectType Ok
     * @throws ApiError
     */
    getAllByOrgId3(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projectTypes',
            query: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param pid
     * @param requestBody
     * @returns ProjectType Ok
     * @throws ApiError
     */
    updateProjectType(pid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/projectTypes/{pid}',
            path: {
                pid: pid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param qid
     * @param requestBody
     * @returns Question Ok
     * @throws ApiError
     */
    updateQuestion(qid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questions/{qid}/update',
            path: {
                qid: qid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param qid
     * @returns string Ok
     * @throws ApiError
     */
    deleteQuestion(qid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/questions/{qid}/delete',
            path: {
                qid: qid,
            },
        });
    }
    /**
     * @param qid
     * @returns string Ok
     * @throws ApiError
     */
    hardDeleteQn(qid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/questions/{qid}/delete/hard',
            path: {
                qid: qid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Scenario Ok
     * @throws ApiError
     */
    createScenario(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/scenarios',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns Scenario Ok
     * @throws ApiError
     */
    updateScenario(requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/scenarios',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @returns Scenario Ok
     * @throws ApiError
     */
    getScenariosForOrg(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/scenarios/org/{orgIdentity}',
            path: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param scid
     * @returns Scenario Ok
     * @throws ApiError
     */
    getScenario(scid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/scenarios/{scid}',
            path: {
                scid: scid,
            },
        });
    }
    /**
     * @param scid
     * @param pIdentity
     * @param requestBody
     * @returns Scenario Ok
     * @throws ApiError
     */
    assignProjectToScenario(scid, pIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/scenarios/{scid}/projects/{pIdentity}',
            path: {
                scid: scid,
                pIdentity: pIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param scid
     * @param pIdentity
     * @returns string Ok
     * @throws ApiError
     */
    removeProjectFromScenario(scid, pIdentity) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/scenarios/{scid}/projects/{pIdentity}',
            path: {
                scid: scid,
                pIdentity: pIdentity,
            },
        });
    }
    /**
     * @param scid
     * @param pIdentity
     * @param requestBody
     * @returns Scenario Ok
     * @throws ApiError
     */
    updateScenarioProjectFundingType(scid, pIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/scenarios/{scid}/projects/{pIdentity}/fundingType',
            path: {
                scid: scid,
                pIdentity: pIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param scenarioIdentity
     * @param requestBody
     * @returns Scenario Ok
     * @throws ApiError
     */
    toggleScenarioLockStatus(scenarioIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/scenarios/{scenarioIdentity}/toggleLockStatus',
            path: {
                scenarioIdentity: scenarioIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param scid
     * @param requestBody
     * @returns TaxImpact Ok
     * @throws ApiError
     */
    addTaxImpact(scid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/scenarios/{scid}/taxImpact',
            path: {
                scid: scid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sid
     * @returns Site Ok
     * @throws ApiError
     */
    getSite(sid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{sid}',
            path: {
                sid: sid,
            },
        });
    }
    /**
     * @param sid
     * @param requestBody
     * @returns Site Ok
     * @throws ApiError
     */
    updateSite(sid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sites/{sid}',
            path: {
                sid: sid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sid
     * @returns string Ok
     * @throws ApiError
     */
    deleteSite(sid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sites/{sid}',
            path: {
                sid: sid,
            },
        });
    }
    /**
     * @param sid
     * @returns Site Ok
     * @throws ApiError
     */
    getSiteEverything(sid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{sid}/everything',
            path: {
                sid: sid,
            },
        });
    }
    /**
     * @param sid
     * @returns Facility Ok
     * @throws ApiError
     */
    getFacilitiesForSite(sid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sites/{sid}/facilities',
            path: {
                sid: sid,
            },
        });
    }
    /**
     * @param sid
     * @param requestBody
     * @returns Site Ok
     * @throws ApiError
     */
    addFacility(sid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sites/{sid}/facilities',
            path: {
                sid: sid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sid
     * @param requestBody
     * @returns Facility Ok
     * @throws ApiError
     */
    postFacility(sid, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sites/{sid}/facilities',
            path: {
                sid: sid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sid
     * @param formData
     * @returns CsvError Ok
     * @throws ApiError
     */
    uploadFacilitiesFromCsv(sid, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sites/{sid}/upload/facilities',
            path: {
                sid: sid,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param tIdentity
     * @param requestBody
     * @returns PhotoTag Ok
     * @throws ApiError
     */
    updatetag(tIdentity, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/phototags/{tIdentity}',
            path: {
                tIdentity: tIdentity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param orgIdentity
     * @returns PhotoTag Ok
     * @throws ApiError
     */
    getAllByOrgIdentity(orgIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/phototags/{orgIdentity}',
            path: {
                orgIdentity: orgIdentity,
            },
        });
    }
    /**
     * @param scIdentity
     * @returns TaxImpact Ok
     * @throws ApiError
     */
    getTaxImpact(scIdentity) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/taxImpact/{scIdentity}',
            path: {
                scIdentity: scIdentity,
            },
        });
    }
    /**
     * @param email
     * @returns UserData Ok
     * @throws ApiError
     */
    getUser(email) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{email}',
            path: {
                email: email,
            },
        });
    }
    /**
     * @returns UserData Ok
     * @throws ApiError
     */
    getRoleUsers() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/systemRole/users',
        });
    }
    /**
     * @returns UserData Ok
     * @throws ApiError
     */
    getConcierges() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/type/concierges',
        });
    }
    /**
     * @returns UserData Ok
     * @throws ApiError
     */
    getAdmins() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/type/admins',
        });
    }
    /**
     * @param requestBody
     * @returns UserData Ok
     * @throws ApiError
     */
    createUser(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns UserData Ok
     * @throws ApiError
     */
    updateUser(id, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uid
     * @param requestBody
     * @returns UserData Ok
     * @throws ApiError
     */
    setUserOrgs(uid, requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/{uid}/setOrgs',
            path: {
                uid: uid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uid
     * @returns OrganizationSnapshot Ok
     * @throws ApiError
     */
    getUserOrgs(uid) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{uid}/orgs',
            path: {
                uid: uid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserData Ok
     * @throws ApiError
     */
    upsertUserSub(requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/confirmations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uid
     * @returns string Ok
     * @throws ApiError
     */
    deleteUserById(uid) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{uid}',
            path: {
                uid: uid,
            },
        });
    }
}
