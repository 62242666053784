import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDependencies } from '../Dependencies/DependenciesContext';
import { ReportsContext } from './ReportsContext';
import { useCurrentOrganization } from '../Organization/OrganizationContext';
export function ReportsProvider({ children }) {
    const { platformApi } = useDependencies();
    const { organization } = useCurrentOrganization();
    const [current, setCurrent] = useState();
    useEffect(() => {
        if (!current && organization) {
            platformApi.report
                .getApiV1ReportList(organization.identity)
                .then((reports) => {
                setCurrent(domainReports(reports));
            });
        }
    }, [organization]);
    return (_jsx(ReportsContext.Provider, { value: {
            reports: current,
        }, children: children }));
}
export function domainReports(reports) {
    const onAppContext = (appContext) => (report) => report.appContext === appContext;
    const organizeReports = reports.filter(onAppContext('Organize'));
    const prioritizeReports = reports.filter(onAppContext('Prioritize'));
    const optimizeReports = reports.filter(onAppContext('Optimize'));
    const toDomain = (report) => ({
        id: report.reportId,
        name: report.reportName,
    });
    return {
        organize: organizeReports.map(toDomain),
        prioritize: prioritizeReports.map(toDomain),
        optimize: optimizeReports.map(toDomain),
    };
}
