import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { PageLayout } from '../components/layouts/PageLayout';
import { appValues } from '../config/constants';
import InvitationErrorModal from '../sharedComponents/InvitationErrorModal';
import InvitationModal from '../sharedComponents/InvitationModal';
import { useAppDispatch } from '../store/hooks';
import { setAvailable, setExpanded } from '../store/sidebarSlice';
const ActivatePage = () => {
    const dispatch = useAppDispatch();
    const { isOpen: isOpenInvitation, onClose: onCloseInvitation } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isOpenInvitationError, onOpen: onOpenInvitationError, onClose: onCloseInvitationError, } = useDisclosure();
    useEffect(() => {
        dispatch(setExpanded(false));
        dispatch(setAvailable(true));
    }, []);
    return (_jsx(PageLayout, { title: 'Activate', children: _jsxs(Flex, { h: `calc(100vh - ${appValues.HEADER_HEIGHT}px - ${appValues.FOOTER_HEIGHT}px)`, children: [_jsx(InvitationModal, { isOpen: isOpenInvitation, onClose: onCloseInvitation, onOpen: onOpenInvitationError }), _jsx(InvitationErrorModal, { isOpen: isOpenInvitationError, onClose: onCloseInvitationError })] }) }));
};
export default ActivatePage;
