import { IAppNames } from '@frontend/domain/models/App';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../objects/UserData';
const orgEditors = [
    OrganizationRoleEnum.LOCAL_ADMIN.toString(),
    OrganizationRoleEnum.CLIENT_CONCIERGE.toString(),
];
export const canEditOrg = (orgRole) => {
    return !!orgEditors.includes(orgRole);
};
export const getOrgRole = (user, orgId) => {
    var _a, _b;
    const systemRole = user.systemRole;
    if (SystemRoleEnum.SUPER_ADMIN === systemRole ||
        SystemRoleEnum.ORG_APPROVER === systemRole) {
        return OrganizationRoleEnum.LOCAL_ADMIN;
    }
    return (_b = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((role) => role.organizationId === orgId)) === null || _b === void 0 ? void 0 : _b.role;
};
export const canResendInvite = (user) => {
    if (user && user.systemRole !== SystemRoleEnum.USER) {
        return true;
    }
    if (user && user.organizationRoles) {
        return user.organizationRoles.some(({ role }) => role === OrganizationRoleEnum.LOCAL_ADMIN);
    }
    else {
        return false;
    }
};
export const canDeleteUser = (currentUser, userToDelete) => {
    if ((userToDelete === null || userToDelete === void 0 ? void 0 : userToDelete.systemRole) === SystemRoleEnum.SUPER_ADMIN)
        return false;
    const isOA = (userToDelete === null || userToDelete === void 0 ? void 0 : userToDelete.systemRole) === SystemRoleEnum.ORG_APPROVER;
    if (currentUser && currentUser.systemRole === SystemRoleEnum.SUPER_ADMIN) {
        return true;
    }
    if (currentUser &&
        currentUser.systemRole === SystemRoleEnum.ORG_APPROVER &&
        userToDelete &&
        (userToDelete.systemRole === SystemRoleEnum.USER ||
            userToDelete.systemRole === SystemRoleEnum.ORG_APPROVER)) {
        return userToDelete.systemRole === SystemRoleEnum.USER;
    }
    if (userToDelete &&
        userToDelete.organizationRoles &&
        currentUser &&
        currentUser.organizationRoles) {
        return (currentUser.organizationRoles.some(({ role }) => role === OrganizationRoleEnum.LOCAL_ADMIN ||
            OrganizationRoleEnum.CLIENT_CONCIERGE) &&
            userToDelete.organizationRoles.length <= 1 &&
            !isOA);
    }
    else {
        return false;
    }
};
export const toSysRoleString = (role) => {
    switch (role) {
        case SystemRoleEnum.SUPER_ADMIN:
            return 'Super Admin';
        case SystemRoleEnum.ORG_APPROVER:
            return 'Org Approver';
        case SystemRoleEnum.USER:
            return 'User';
        default:
            return '';
    }
};
export const toOrgRoleString = (role) => {
    switch (role) {
        case OrganizationRoleEnum.LOCAL_ADMIN:
            return 'Local Admin';
        case OrganizationRoleEnum.CLIENT_CONCIERGE:
            return 'Client Concierge';
        case OrganizationRoleEnum.MEMBER:
            return 'Member';
        case OrganizationRoleEnum.VISITOR:
            return 'Visitor';
        default:
            return '';
    }
};
export const hasRole = (roles, user, orgId) => {
    const rolesFound = [];
    if (user.systemRole) {
        rolesFound.push(roles.some((role) => role === user.systemRole));
    }
    const userOrgRole = user.organizationRoles;
    if (userOrgRole && userOrgRole.length > 0 && orgId) {
        const orgRole = userOrgRole.find((role) => role.organizationId === orgId);
        if (orgRole) {
            rolesFound.push(roles.some((role) => role === orgRole.role));
        }
    }
    return rolesFound.some((role) => role === true);
};
export const hasAccess = (currentUser, currentOrg) => {
    var _a, _b, _c;
    if (!currentUser || currentUser.id === 'null') {
        return false;
    }
    // Check if user automatically has access to all apps
    if (SystemRoleEnum.SUPER_ADMIN === currentUser.systemRole ||
        SystemRoleEnum.ORG_APPROVER === currentUser.systemRole) {
        return true;
    }
    const apps = currentUser.apps;
    // has current org + apps => check org role to see auto access or explicit access to reports for org
    // no current org + apps => check if any are reports
    // has current org + no apps => check if local admin / client concierge
    // no current org + no apps => false (or they are super user)
    // if no current org, check if the user has access to reports at all
    if (!currentOrg) {
        return !!(apps === null || apps === void 0 ? void 0 : apps.find((app) => IAppNames.REPORTS === app.app));
    }
    // check if user automatically has access to reports through org
    const currentUserOrgRole = (_b = (_a = currentUser.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => currentOrg.id === orgRole.organizationId)) === null || _b === void 0 ? void 0 : _b.role;
    if (OrganizationRoleEnum.LOCAL_ADMIN === currentUserOrgRole ||
        OrganizationRoleEnum.CLIENT_CONCIERGE === currentUserOrgRole) {
        return !!((_c = currentOrg.apps) === null || _c === void 0 ? void 0 : _c.find((orgApp) => IAppNames.REPORTS === orgApp.app));
    }
    // check if user was granted access to reports for the current org
    return !!(apps === null || apps === void 0 ? void 0 : apps.find((app) => IAppNames.REPORTS === app.app &&
        currentOrg.id === app.organizationId));
};
export const hasPIQAndNonExpiredAccess = (orgApps) => {
    const piqApp = orgApps === null || orgApps === void 0 ? void 0 : orgApps.find((app) => app.app === IAppNames.PIQ);
    if (!piqApp) {
        return false;
    }
    if (!piqApp.expiresOn) {
        return true;
    }
    return new Date() < new Date(piqApp.expiresOn || '');
};
