import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { UserOrganizationTableRow } from './UserOrganizationTableRow';
export const UserOrganizationTable = ({ user, orgs, }) => {
    const renderRows = () => {
        if (user && user.organizationRoles) {
            return (_jsx(_Fragment, { children: user.organizationRoles.map((orgRole) => {
                    const org = orgs === null || orgs === void 0 ? void 0 : orgs.find((org) => org.id === orgRole.organizationId);
                    if (org) {
                        return (_jsx(UserOrganizationTableRow, { user: user, organizationRole: orgRole, org: org }, `user table row ${orgRole.organizationIdentity}`));
                    }
                    return _jsx(_Fragment, {});
                }) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsx(Stack, { children: _jsxs(Table, { bg: 'rgba(251, 218, 189, 0.15)', borderLeft: `2px solid ${appColors.SEC_ORANGE}`, children: [_jsx(Thead, { children: _jsxs(Tr, { fontWeight: '600', fontSize: '14px', color: appColors.TEXT_COLOR, children: [_jsx(Td, { children: "Organization" }), _jsx(Td, { children: "Organization Role" }), _jsx(Td, { children: "Facility" }), _jsx(Td, { children: "Facility Role" }), _jsx(Td, { children: "Apps Access" })] }) }), _jsx(Tbody, { children: renderRows() })] }) }));
};
