import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, Radio, RadioGroup, Spacer, Switch, Text, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { IAppNames } from '@frontend/domain/models/App';
import { useState } from 'react';
import { appColors } from '../../../config/constants';
import { getAppNameAndLogo } from '../../../config/helperFunctions';
import { ModalType, OrganizationRoleEnum, SystemRoleEnum, } from '../../../objects/UserData';
import { useAppSelector } from '../../../store/hooks';
import { selectUserData } from '../../../store/userDataSlice';
import { hasRole } from '../../../utils/userRoleUtils';
import { AddAdminOrConciergeModal } from '../ConciergeAndAdminModals/AddConciergeOrAdminModal';
export const UsersAccess = ({ canEditAppContext, editMode, newPrimaryContactIdIdentity, org, orgAdmins, register, renderText, requiredFields, resetField, setAddAdminOrConcierge, setNewPrimaryContactIdIdentity, setValue, watch, setClientConciergeOnNewOrg, localAdminsOnNewOrg, setLocalAdminsOnNewOrg, primaryContactOnNewOrgEmail, setPrimaryContactOnNewOrgEmail, }) => {
    const [modalType, setModalType] = useState(ModalType.admin);
    const user = useAppSelector(selectUserData);
    const { isOpen: isOpenAddConcierge, onOpen: onOpenAddConcierge, onClose: onCloseAddConcierge, } = useDisclosure();
    const { isOpen: isOpenAddAdmin, onOpen: onOpenAddAdmin, onClose: onCloseAddAdmin, } = useDisclosure();
    const reportsExpiresOnRegister = register(`${REPORTS_KEY}.${EXPIRES_ON_KEY}`);
    const fcaExpiresOnRegister = register(`${FCA_KEY}.${EXPIRES_ON_KEY}`);
    const plannerExpiresOnRegister = register(`${PLANNER_KEY}.${EXPIRES_ON_KEY}`);
    const piqExpiresOnRegister = register(`${PIQ_KEY}.${EXPIRES_ON_KEY}`);
    const [reportsWatch, plannerWatch, fcaWatch, piqWatch] = watch([
        REPORTS_KEY,
        PLANNER_KEY,
        FCA_KEY,
        PIQ_KEY,
    ]);
    const canAddLA = user &&
        hasRole([
            SystemRoleEnum.SUPER_ADMIN,
            SystemRoleEnum.ORG_APPROVER,
            OrganizationRoleEnum.CLIENT_CONCIERGE,
        ], user, org === null || org === void 0 ? void 0 : org.id);
    const renderClientConcierge = (label) => {
        return (_jsx(FormControl, { isRequired: editMode && requiredFields.includes(label), children: _jsxs(HStack, { display: 'flex', justifyContent: 'space-between', children: [renderText(label), editMode &&
                        user &&
                        user.systemRole !== SystemRoleEnum.USER && (_jsx(Button, { variant: variants.editBtn, onClick: () => {
                            setAddAdminOrConcierge(true);
                            setModalType(ModalType.concierge);
                            onOpenAddConcierge();
                        }, children: "Replace" })), _jsx(AddAdminOrConciergeModal, { org: org, modalType: modalType, setAddAdminOrConcierge: setAddAdminOrConcierge, isOpen: isOpenAddConcierge, onClose: onCloseAddConcierge, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg })] }) }));
    };
    const renderLocalAdmin = (label) => {
        return (_jsxs(FormControl, { isRequired: editMode && requiredFields.includes(label), children: [_jsxs(HStack, { display: 'flex', justifyContent: 'space-between', children: [_jsxs(Box, { children: [_jsx(FormLabel, { color: appColors.PRIM_BLUE, mb: '0', children: label }), _jsx(Text, { fontSize: '10px', paddingLeft: '18px', children: "Select one primary contact" })] }), editMode && canAddLA && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: variants.editBtn, onClick: () => {
                                        setAddAdminOrConcierge(true);
                                        setModalType(ModalType.admin);
                                        onOpenAddAdmin();
                                    }, children: "Add new" }), _jsx(AddAdminOrConciergeModal, { org: org, modalType: modalType, setAddAdminOrConcierge: setAddAdminOrConcierge, isOpen: isOpenAddAdmin, onClose: onCloseAddAdmin, setClientConciergeOnNewOrg: setClientConciergeOnNewOrg, localAdminsOnNewOrg: localAdminsOnNewOrg, setLocalAdminsOnNewOrg: setLocalAdminsOnNewOrg })] }))] }), localAdminsOnNewOrg.length > 0 && (_jsx(RadioGroup, { onChange: (e) => setPrimaryContactOnNewOrgEmail(e), value: primaryContactOnNewOrgEmail, isDisabled: !editMode, children: localAdminsOnNewOrg.map((admin, i) => {
                        return (_jsx(HStack, { ml: '15px', children: _jsx(Radio, { value: admin.emailAddress, colorScheme: 'orange', children: _jsxs(Text, { children: [admin.firstName, " ", admin.lastName] }) }) }, `${admin.emailAddress} ${i}`));
                    }) })), localAdminsOnNewOrg.length === 0 && (_jsx(RadioGroup, { onChange: (e) => setNewPrimaryContactIdIdentity(e), value: newPrimaryContactIdIdentity, isDisabled: !editMode, children: orgAdmins &&
                        orgAdmins.map((admin, i) => {
                            var _a, _b;
                            return (_jsx(HStack, { ml: '15px', children: _jsx(Radio, { value: `${(_a = admin.id) === null || _a === void 0 ? void 0 : _a.toUpperCase()},${admin.userIdentity}`, colorScheme: 'orange', children: _jsxs(Text, { children: [admin.firstName, ' ', admin.lastName] }) }) }, `${(_b = admin.id) === null || _b === void 0 ? void 0 : _b.toUpperCase()} ${i}`));
                        }) }))] }));
    };
    const setOrgApp = (field, checked) => {
        let app = IAppNames.REPORTS;
        switch (field) {
            case PLANNER_KEY:
                app = IAppNames.PLANNER;
                break;
            case FCA_KEY:
                app = IAppNames.FCA;
                break;
            case PIQ_KEY:
                app = IAppNames.PIQ;
                break;
        }
        if (checked) {
            setValue(`${field}.${APP_KEY}`, app);
        }
        else {
            resetField(`${field}.${EXPIRES_ON_KEY}`);
            setValue(field, undefined);
        }
    };
    const renderAppCheckboxRow = (app, key, expiresOnRegister, watch) => {
        const { icon, name } = getAppNameAndLogo(app);
        return (_jsxs(Flex, { align: 'center', mt: '10px', flexWrap: 'wrap', children: [_jsxs(Flex, { align: 'center', children: [_jsx(Box, { children: _jsx(Image, { src: icon, mr: '5px', boxSize: '30px' }) }), _jsx(Heading, { as: 'h5', size: 'xs', w: '100px', children: name })] }), _jsx(Spacer, {}), _jsxs(Flex, { align: 'center', children: [_jsx(Text, { fontSize: 'sm', mr: '5px', children: "Access\u00A0Available\u00A0Until" }), _jsx(Input, Object.assign({ type: 'date', mr: '5px', size: 'xs' }, expiresOnRegister, { 
                            // isRequired={!!watch?.app}
                            isDisabled: !editMode })), _jsx(Switch, { colorScheme: 'orange', size: 'sm', isDisabled: !editMode, isChecked: !!(watch === null || watch === void 0 ? void 0 : watch.app), onChange: (e) => setOrgApp(key, e.target.checked), "aria-label": key })] })] }));
    };
    const renderApps = () => {
        return (_jsxs(Box, { mt: '20px !important', children: [_jsx(Heading, { size: 'md', children: "Access" }), _jsxs(FormControl, { children: [renderAppCheckboxRow(IAppNames.REPORTS, REPORTS_KEY, reportsExpiresOnRegister, reportsWatch), renderAppCheckboxRow(IAppNames.FCA, FCA_KEY, fcaExpiresOnRegister, fcaWatch), renderAppCheckboxRow(IAppNames.PLANNER, PLANNER_KEY, plannerExpiresOnRegister, plannerWatch), renderAppCheckboxRow(IAppNames.PIQ, PIQ_KEY, piqExpiresOnRegister, piqWatch)] })] }));
    };
    return (_jsxs(_Fragment, { children: [renderClientConcierge('Client concierge'), renderLocalAdmin('Local Admin'), canEditAppContext && renderApps()] }));
};
const REPORTS_KEY = 'reports';
const PLANNER_KEY = 'planner';
const FCA_KEY = 'fca';
const PIQ_KEY = 'piq';
const APP_KEY = 'app';
const EXPIRES_ON_KEY = 'expiresOn';
