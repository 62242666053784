import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppSelector } from '@/store/hooks';
import { memoizedSelectOrganization } from '@/store/orgSlice';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Table, Tbody, Td, Thead, Tr, useDisclosure, VStack, } from '@chakra-ui/react';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
import { variants } from '@frontend/design-system/theme/theme';
import { IVerticalMarketNames } from '@frontend/domain/models/Organization/OrganizationInterfaces';
import { lazy, Suspense, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { canEditOrg } from '../../../utils/userRoleUtils';
import { FacilitiesTableRow } from './FacilitiesTableRow';
import { useMyFacilitiesContext } from './MyFacilitiesContext';
const EditFacilityModal = lazy(() => import('./EditFacilityModal'));
export const FacilitiesTable = () => {
    var _a, _b, _c;
    const myFacilitiesContext = useMyFacilitiesContext();
    const { sites, orgRole } = myFacilitiesContext;
    const { org } = useAppSelector(memoizedSelectOrganization);
    const denormalizedFacilities = sites === null || sites === void 0 ? void 0 : sites.reduce((acc, site) => {
        const facilitiesWithSiteName = site.facilities.map((facility) => {
            return {
                facility,
                siteName: site.name,
                siteId: site.id,
            };
        });
        return [...acc, ...(facilitiesWithSiteName !== null && facilitiesWithSiteName !== void 0 ? facilitiesWithSiteName : [])];
    }, []);
    const [facilityToEdit, setFacilityToEdit] = useState(undefined);
    const [facilitySiteIdToEdit, setFacilitySiteIdToEdit] = useState('');
    const { isOpen: isOpenAddFacility, onOpen: onOpenAddFacility, onClose: onCloseAddFacility, } = useDisclosure();
    const { isOpen: isOpenEditFacility, onOpen: onOpenEditFacility, onClose: onCloseEditFacility, } = useDisclosure();
    const canEdit = canEditOrg(orgRole);
    const tableContainerRef = useRef(null);
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: (_a = denormalizedFacilities === null || denormalizedFacilities === void 0 ? void 0 : denormalizedFacilities.length) !== null && _a !== void 0 ? _a : 0,
        overscan: 40,
    });
    const { virtualItems: virtualRows } = rowVirtualizer;
    return (_jsxs(VStack, { w: '100%', alignItems: 'start', ref: tableContainerRef, children: [canEdit && (_jsx(Button, { onClick: onOpenAddFacility, w: '205px', h: '43px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New Facility" })), _jsxs(Table, { children: [_jsx(Thead, { layerStyle: 'thead', children: _jsxs(Tr, { children: [_jsx(Td, { children: "Facility" }), _jsx(Td, { children: "Site" }), _jsx(Td, { children: "Latitude / Longitude" }), _jsx(Td, { children: "Primary address" }), _jsx(Td, { children: "Property Size" }), (org === null || org === void 0 ? void 0 : org.verticalMarket) ===
                                    IVerticalMarketNames.K12_SCHOOLS && (_jsx(Td, { children: "Number of Students" })), _jsx(Td, {})] }) }), _jsx(Tbody, { children: virtualRows.map((facility) => {
                            var _a;
                            const row = denormalizedFacilities === null || denormalizedFacilities === void 0 ? void 0 : denormalizedFacilities[facility.index];
                            if (row)
                                return (_jsx(FacilitiesTableRow, { onOpenEditFacility: onOpenEditFacility, setFacilityToEdit: setFacilityToEdit, setFacilitySiteIdToEdit: setFacilitySiteIdToEdit, facility: row.facility, siteName: row.siteName, siteId: row.siteId, orgVerticalMarket: (_a = org === null || org === void 0 ? void 0 : org.verticalMarket) !== null && _a !== void 0 ? _a : null, canEdit: canEdit }, `${row.facility.facilityId}`));
                        }) })] }), canEdit && (_jsxs(_Fragment, { children: [_jsx(Suspense, { fallback: _jsx(Loading, {}), children: (org === null || org === void 0 ? void 0 : org.orgIdentity) && (_jsx(EditFacilityModal, { orgVerticalMarket: (_b = org === null || org === void 0 ? void 0 : org.verticalMarket) !== null && _b !== void 0 ? _b : null, isOpen: isOpenAddFacility, onClose: onCloseAddFacility, orgIdentity: org.orgIdentity })) }), facilityToEdit && (_jsx(Suspense, { fallback: _jsx(Loading, {}), children: (org === null || org === void 0 ? void 0 : org.orgIdentity) && (_jsx(EditFacilityModal, { facilityToEdit: facilityToEdit, orgVerticalMarket: (_c = org === null || org === void 0 ? void 0 : org.verticalMarket) !== null && _c !== void 0 ? _c : null, facilitySiteIdToEdit: facilitySiteIdToEdit, isOpen: isOpenEditFacility, onClose: () => {
                                onCloseEditFacility();
                                setFacilityToEdit(undefined);
                            }, orgIdentity: org.orgIdentity })) }))] }))] }));
};
