import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReports } from '../../components/context/ReportsContext';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../../objects/UserData';
import { usePlatformUserApi } from '../../services/api/platformUserApiHook';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchStates, fetchVerticalMarkets } from '../../store/lookupsSlice';
import { fetchOrgById, fetchPlatformSitesByOrgId, memoizedSelectOrganization, } from '../../store/orgSlice';
import { selectUserData } from '../../store/userDataSlice';
import { useOrgUserRole } from '../../store/userRole';
import { hasRole } from '../../utils/userRoleUtils';
import { ReportTable } from './Admin/ReportTable';
import { MyFacilitiesTab } from './Facilities/MyFacilitiesTab';
import { GeneralInput } from './Organization/GeneralInput';
import { MyPlanningPage } from './Planning/MyPlanning';
import { MyPhotosTab } from './Tags/MyPhotosTab';
import { UsersTable } from './Users/UsersTable';
export const OrganizationDetailsPage = () => {
    const params = useParams();
    const { org } = useAppSelector(memoizedSelectOrganization);
    const user = useAppSelector(selectUserData);
    const [orgUsers, setOrgUsers] = useState([]);
    const myPhotos = useFeatureFlag(FeatureFlags.FF_ADD_PHOTO_TAG);
    const orgRole = useOrgUserRole(user, org === null || org === void 0 ? void 0 : org.id);
    const { orgUsersList } = usePlatformUserApi();
    const { selectReport } = useReports();
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal, } = useDisclosure();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchVerticalMarkets());
        dispatch(fetchStates());
    }, []);
    useEffect(() => {
        if (params.oid) {
            dispatch(fetchOrgById(params.oid));
        }
    }, [params.oid]);
    useEffect(() => {
        getOrgUsersList();
        if (org === null || org === void 0 ? void 0 : org.orgIdentity) {
            dispatch(fetchPlatformSitesByOrgId(org.orgIdentity));
        }
    }, [org]);
    const getOrgUsersList = () => {
        if (org === null || org === void 0 ? void 0 : org.orgIdentity) {
            orgUsersList({ organizationId: org.orgIdentity }).then((usersList) => {
                usersList &&
                    setOrgUsers(filterUsers(usersList, org.orgIdentity));
            });
        }
    };
    const renderDetails = () => {
        var _a;
        const showAdmin = org &&
            user &&
            hasRole([
                SystemRoleEnum.SUPER_ADMIN,
                SystemRoleEnum.ORG_APPROVER,
                OrganizationRoleEnum.CLIENT_CONCIERGE,
            ], user, org.id);
        return (_jsx(_Fragment, { children: _jsxs(Tabs, { w: '100%', h: '100%', isLazy: true, children: [_jsxs(TabList, { mb: '15px', children: [_jsx(Tab, { children: _jsx("b", { children: "myOrganization" }) }), org && (_jsxs(_Fragment, { children: [_jsx(Tab, { children: _jsx("b", { children: "myFacilities" }) }), myPhotos &&
                                        orgRole !== OrganizationRoleEnum.MEMBER &&
                                        orgRole !==
                                            OrganizationRoleEnum.VISITOR && (_jsx(Tab, { children: _jsx("b", { children: "myGallery" }) })), _jsx(Tab, { children: _jsx("b", { children: "myPlanning" }) }), _jsx(Tab, { children: _jsx("b", { children: "myUsers" }) }), showAdmin && (_jsx(Tab, { children: _jsx("b", { children: "Admin" }) }))] }))] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(GeneralInput, { orgParams: (_a = params.oid) !== null && _a !== void 0 ? _a : '', orgRole: orgRole, orgUsers: orgUsers, systemRole: (user === null || user === void 0 ? void 0 : user.systemRole) || SystemRoleEnum.USER }) }), org && (_jsx(TabPanel, { children: _jsx(MyFacilitiesTab, { orgRole: orgRole }) })), myPhotos &&
                                orgRole !== OrganizationRoleEnum.MEMBER &&
                                orgRole !== OrganizationRoleEnum.VISITOR &&
                                org && (_jsx(TabPanel, { children: _jsx(MyPhotosTab, { org: org, orgRole: orgRole }) })), org && (_jsx(TabPanel, { children: _jsx(PlannerSettingsProvider, { orgIdentity: org.orgIdentity, children: _jsx(MyPlanningPage, { orgRole: orgRole, org: org }) }) })), org && (_jsx(TabPanel, { children: _jsx(UsersTable, { org: org, orgUsers: orgUsers, orgRole: orgRole, getOrgUsersList: getOrgUsersList }) })), org && (_jsx(TabPanel, { children: _jsxs(_Fragment, { children: [_jsxs(Flex, { mb: '20px', justifyContent: 'space-between', children: [_jsx(Heading, { as: 'h4', size: 'md', fontWeight: 600, color: appColors.BLACK, children: "Report Assignment" }), _jsx(Button, { variant: variants.transparentBlueBtn, leftIcon: _jsx(AddIcon, { w: '12px', h: '12px' }), borderRadius: '18px', fontSize: '14px', onClick: () => {
                                                        selectReport(undefined);
                                                        onOpenModal();
                                                    }, children: "Add New" })] }), _jsx(ReportTable, { orgIdentity: org.orgIdentity, onOpen: onOpenModal, isOpen: isOpenModal, onClose: onCloseModal })] }) }))] })] }) }));
    };
    return (
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _jsx(PageLayout, { title: params.oid ? org === null || org === void 0 ? void 0 : org.name : 'Create Organization', hideOrgsDropdown: !params.oid, children: !(org === null || org === void 0 ? void 0 : org.isDeleted) ? (renderDetails()) : (_jsx(Text, { children: "This organization has been deleted." })) }));
};
export const filterUsers = (usersList, orgIdentity) => {
    return usersList.filter((user) => {
        var _a;
        return (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => orgRole.organizationIdentity === orgIdentity);
    });
};
