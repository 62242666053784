import { jsx as _jsx } from "react/jsx-runtime";
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
export const NavLink = ({ children, href }) => {
    if (href.includes('https://') || href.includes('http://')) {
        return _jsx(ChakraLink, { href: href, children: children });
    }
    else {
        return _jsx(ReactLink, { to: href, children: children });
    }
};
