import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, VStack } from '@chakra-ui/react';
import { useAppSelector } from '../../../store/hooks';
import { selectStates, selectVerticalMarkets, } from '../../../store/lookupsSlice';
import { SitesTable } from '../Sites/SitesTable';
import { FacilitiesTable } from './FacilitiesTable';
import { MyFacilitiesContext } from './MyFacilitiesContext';
import { memoizedSelectPlatformSites } from '@/store/orgSlice';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
export const MyFacilitiesTab = ({ orgRole }) => {
    const { sites, loading } = useAppSelector(memoizedSelectPlatformSites);
    const verticalMarkets = useAppSelector(selectVerticalMarkets);
    const states = useAppSelector(selectStates);
    return (_jsx(_Fragment, { children: sites && (_jsx(MyFacilitiesContext.Provider, { value: {
                sites,
                orgRole,
                verticalMarkets,
                states,
            }, children: _jsx(VStack, { spacing: 6, children: !loading ? (_jsxs(_Fragment, { children: [_jsx(SitesTable, {}), _jsx(FacilitiesTable, {})] })) : (_jsx(Center, { position: 'absolute', width: '80%', height: '60%', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '100', children: _jsx(Loading, {}) })) }) })) }));
};
