export var IVerticalMarketNames;
(function (IVerticalMarketNames) {
    IVerticalMarketNames["COMMERCIAL_REAL_ESTATE"] = "Commercial Real Estate";
    IVerticalMarketNames["FEDERAL_GOVERNMENT"] = "Federal Government";
    IVerticalMarketNames["HEALTHCARE_SYSTEM"] = "Healthcare System";
    IVerticalMarketNames["HIGHER_EDUCATION"] = "Higher Education";
    IVerticalMarketNames["K12_SCHOOLS"] = "K-12 Schools";
    IVerticalMarketNames["MANUFACTURING_DISTRIBUTION"] = "Manufacturing & Distribution";
    IVerticalMarketNames["STATE_LOCAL_GOVERNMENT"] = "State & Local Government";
})(IVerticalMarketNames || (IVerticalMarketNames = {}));
