import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../config/paths';
import { getAllOrgsWithRelations } from '../../../services/api/orgDetailsAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setOrganization, toggleOrgsCreateMode } from '../../../store/orgSlice';
import { selectUserData } from '../../../store/userDataSlice';
import { TableType } from '../SystemAdminPage';
import { SystemAdminTable } from '../Table/SystemAdminTable';
import ActionsBodyOrgModal from './ActionsBodyOrgModal';
import { OrgManagementRow } from './OrgManagementRow';
export const OrgManagement = () => {
    const user = useAppSelector(selectUserData);
    const [allOrgs, setAllOrgs] = useState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        getAllOrgsWithRelations([
            'organizationRoles',
            'clientConcierge',
            'apps',
        ]).then((orgs) => setAllOrgs(orgs));
    }, []);
    const orgManagementTableHeaders = [
        'Organization',
        'Business unit',
        'Industry vertical',
        'State',
        'Client Concierge',
        '# Users',
    ];
    const renderRows = () => {
        if (allOrgs) {
            return (_jsx(_Fragment, { children: allOrgs.map((org) => (_jsx(OrgManagementRow, { org: org, popoverMenu: _jsx(ActionsBodyOrgModal, { user: user, orgToArchive: org }) }, org.id))) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { width: '100%', display: 'flex', justifyContent: 'flex-end', children: _jsx(Button, { onClick: () => {
                        dispatch(setOrganization(undefined));
                        dispatch(toggleOrgsCreateMode(true));
                        navigate(Paths.ORG_DETAILS_CREATE);
                    }, w: '124px', h: '36px', marginBottom: '15px', variant: variants.addNewBtn, leftIcon: _jsx(AddIcon, {}), children: "Add New" }) }), _jsx(SystemAdminTable, { tableName: TableType.org, tableHeaders: orgManagementTableHeaders, children: renderRows() })] }));
};
