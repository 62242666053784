import axios from 'axios';
import { environment } from '../config/environment';
import FacilityService from './facilityService';
import FeatureFlagService from './featureFlagService';
import LookupsService from './lookupsService';
import OrgService from './orgService';
import RestService from './RestService';
import SiteService from './siteService';
import TagService from './tagService';
import UserService from './userService';
export const apiService = new RestService(axios, environment.apiBase);
export const platformApiService = new RestService(axios, environment.platformApi);
const userService = new UserService(apiService, platformApiService);
const orgService = new OrgService(apiService, platformApiService);
const siteService = new SiteService(apiService);
const facilityService = new FacilityService(apiService);
const featureFlagService = new FeatureFlagService(apiService);
const lookupsService = new LookupsService(apiService);
const tagService = new TagService(apiService);
const services = {
    userService,
    orgService,
    siteService,
    facilityService,
    featureFlagService,
    lookupsService,
    tagService,
};
export default services;
