import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccordionItem, Flex, Icon, Text, } from '@chakra-ui/react';
import { NavLink } from './NavLink';
export const NavBarLink = ({ iconSrc, selectedIcon, baseLink, title, expanded, }) => {
    const loc = window.location.href;
    let isCurrentPage = false;
    if (baseLink) {
        isCurrentPage = loc.includes(baseLink);
    }
    return (_jsx(AccordionItem, { border: 'none', children: _jsx("h2", { children: expanded ? (_jsx(NavLink, { href: baseLink, children: _jsxs(Flex, { pl: '16px', py: '8px', alignItems: 'center', children: [_jsx(Icon, { as: isCurrentPage ? selectedIcon : iconSrc, boxSize: '40px', mr: '20px' }), _jsx(Text, { textAlign: 'center', fontWeight: isCurrentPage ? 600 : 400, children: title })] }) })) : (_jsx(Flex, { pl: '16px', py: '8px', alignItems: 'center', children: _jsx(Icon, { as: isCurrentPage ? selectedIcon : iconSrc, boxSize: '40px', mr: '20px' }) })) }) }));
};
