var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertConfigurationResponseToOrgIds, convertOrganizationSummaryResponseToOrganizationSummary, convertOrganizationToRequest, } from '../models/Organization/OrganizationConversions';
export const useOrganizationService = ({ platformApi, }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchOrganizationList = () => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.getApiV1OrganizationList(), {
            error: { label: 'Error occured retrieving User Orgs' },
            success: { enabled: false },
        }).then((res) => res.map((org) => convertOrganizationSummaryResponseToOrganizationSummary(org)));
    });
    const upsertOrganization = (org, image, apps) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.postApiV1OrganizationConfigure(convertOrganizationToRequest(org, image, apps || [])), {
            error: {
                label: `Error occurred updating Organization: ${org.nameOfOrg}`,
            },
            success: {
                enabled: true,
                label: `Updated Organization: ${org.nameOfOrg}`,
            },
        }).then((res) => convertConfigurationResponseToOrgIds(res));
    });
    return {
        organization: {
            fetchList: fetchOrganizationList,
            update: upsertOrganization,
        },
    };
};
