import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PageLayout } from '../../components/layouts/PageLayout';
import { usePlatformUserApi } from '../../services/api/platformUserApiHook';
import { useAppDispatch } from '../../store/hooks';
import { fetchAllOrgs } from '../../store/orgSlice';
import { OrgManagement } from './OrgManagement/OrgManagement';
import { SystemAdmin } from './SystemAdmin/SystemAdmin';
import { UserManagement } from './UserManagement/UserManagement';
export var TableType;
(function (TableType) {
    TableType["org"] = "Org Management";
    TableType["user"] = "User Management";
    TableType["systemAdmin"] = "System Admin";
})(TableType || (TableType = {}));
export const SystemAdminPage = () => {
    const dispatch = useAppDispatch();
    const [users, setUsers] = useState();
    const { getUsersList } = usePlatformUserApi();
    const getUpdatedUsersList = () => {
        getUsersList().then((users) => setUsers(users));
    };
    if (!users) {
        getUpdatedUsersList();
    }
    useEffect(() => {
        dispatch(fetchAllOrgs());
    }, []);
    return (_jsx(PageLayout, { title: 'System Admin', hideOrgsDropdown: true, children: _jsxs(Tabs, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: "Org Management" }), _jsx(Tab, { children: "User Management" }), _jsx(Tab, { children: "System Admin" })] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(OrgManagement, {}) }), _jsx(TabPanel, { children: _jsx(UserManagement, { users: users || [], onUserUpdate: getUpdatedUsersList }) }), _jsx(TabPanel, { children: _jsx(SystemAdmin, { users: users || [], onUserUpdate: getUpdatedUsersList }) })] })] }) }));
};
