import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Icon, IconButton, Stack, Text, VStack, } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { UserIconPrimBlue } from '../../config/icons';
import { Paths } from '../../config/paths';
import { useAppSelector } from '../../store/hooks';
import { selectUserData } from '../../store/userDataSlice';
import { toSysRoleString } from '../../utils/userRoleUtils';
import { NavLink } from './NavLink';
export const ProfileControl = ({ expanded, }) => {
    const userCurrent = useAppSelector(selectUserData);
    return (_jsx(_Fragment, { children: _jsxs(Stack, { flexDirection: expanded ? 'row' : 'column', alignItems: 'center', justifyContent: 'space-evenly', children: [_jsx(IconButton, { boxSize: 50, icon: _jsx(UserIconPrimBlue, { boxSize: 50 }), "aria-label": 'open profile modal', variant: 'transparent-ico-btn', borderColor: 'brand.primBlue', borderWidth: 1, borderRadius: '50%' }), expanded && userCurrent && (_jsxs(VStack, { alignItems: 'start', w: '50%', children: [_jsx(NavLink, { href: Paths.USER_PROFILE, children: _jsxs(HStack, { w: '100%', children: [_jsx(Text, { maxWidth: '50%', noOfLines: 1, fontWeight: 600, fontSize: '14px', children: userCurrent.firstName }), _jsx(Text, { maxWidth: '50%', noOfLines: 1, fontWeight: 600, fontSize: '14px', children: userCurrent.lastName })] }) }), _jsx(Text, { fontWeight: 400, fontSize: '12px', children: userCurrent &&
                                userCurrent.systemRole &&
                                toSysRoleString(userCurrent.systemRole) })] })), _jsx(NavLink, { href: Paths.LOGOUT, children: _jsx(HStack, { justifyContent: 'center', children: _jsx(Icon, { as: FiLogOut, boxSize: '20px' }) }) })] }) }));
};
