var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { updateTag } from '../../../services/api/orgDetailsAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchOrgById, memoizedSelectOrganization, } from '../../../store/orgSlice';
export const EditTagModal = ({ tagToEdit, orgIdentity, orgTags, isOpen, onClose, }) => {
    const [confirmationView, setConfirmationView] = useState(false);
    const { org: orgCurrent } = useAppSelector(memoizedSelectOrganization);
    const { register, handleSubmit, setValue, reset, watch } = useForm();
    const dispatch = useAppDispatch();
    const toast = useToast();
    const nameRegister = register(NAME_KEY, { required: true });
    useEffect(() => {
        var _a;
        if (tagToEdit.name) {
            setValue(NAME_KEY, (_a = tagToEdit.name) !== null && _a !== void 0 ? _a : '');
        }
    }, [setValue, tagToEdit]);
    const renderInput = (register) => {
        return (_jsxs(FormControl, { children: [_jsx(Input, Object.assign({ "data-testid": 'edit-tag-name', type: 'string', placeholder: 'tag' }, register)), renderError()] }));
    };
    const renderError = () => {
        const tagFound = orgTags
            .filter((tag) => tag.name !== tagToEdit.name)
            .find((tag) => tag.name === watch('name'));
        if (tagFound) {
            return (_jsx("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: "Tag name already exists." }));
        }
        if (watch('name') && watch('name').length > 16) {
            return (_jsx("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: "Tag name is too long." }));
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (tagToEdit.tagIdentity) {
                yield updateTag(tagToEdit.tagIdentity, {
                    orgIdentity: orgIdentity,
                    tagToUpdate: {
                        name: data.name,
                        color: tagToEdit.color,
                    },
                });
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
                orgCurrent && dispatch(fetchOrgById(orgCurrent.id));
                onClose();
                runReset();
            }
        }
        catch (_a) {
            toast({
                title: 'Save failed',
                description: 'Please check that all fields are entered correctly.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const renderWarning = () => {
        return (_jsxs(Box, { children: [_jsx("p", { style: {
                        paddingTop: 1,
                        paddingLeft: 1,
                    }, children: "You are about to change the name of a tag. This action will rename the tag across all photos." }), _jsx("p", { style: { marginTop: '20px', paddingLeft: 1 }, children: "Are you sure you want to continue?" })] }));
    };
    const hasError = () => {
        const tagFound = orgTags
            .filter((tag) => tag.name !== tagToEdit.name)
            .find((tag) => tag.name === watch('name'));
        if (tagFound) {
            return true;
        }
        else if (watch('name') && watch('name').length > 16) {
            return true;
        }
        else {
            return false;
        }
    };
    const runReset = () => {
        reset({
            name: '',
        });
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
            setConfirmationView(false);
        }, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, { borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "Rename Tag" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { minH: '100px', children: _jsx(Stack, { py: 2, spacing: 5, children: confirmationView
                                    ? renderWarning()
                                    : renderInput(nameRegister) }) }), _jsxs(ModalFooter, { pt: 5, display: 'flex', justifyContent: 'space-between', children: [_jsx(Button, { px: '20px', py: '8px', variant: variants.orangeTransparentModalBtn, fontSize: '14px', onClick: () => {
                                        onClose();
                                        setConfirmationView(false);
                                    }, children: "Cancel" }), confirmationView ? (_jsx(Button, { type: 'submit', px: '20px', py: '8px', variant: variants.addNewBtn, fontSize: '14px', _hover: { variant: variants.secDarkGrayBtn }, children: "Continue" })) : (_jsx(Button, { onClick: (e) => {
                                        e.preventDefault();
                                        if (!confirmationView) {
                                            setConfirmationView(true);
                                        }
                                    }, px: '20px', py: '8px', variant: variants.addNewBtn, fontSize: '14px', _hover: { variant: variants.secDarkGrayBtn }, isDisabled: hasError(), children: "Save" }))] })] }) })] }));
};
const NAME_KEY = 'name';
