import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureFlags, useFeatureFlag } from '@/store/featureFlag';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../config/paths';
import { SystemRoleEnum } from '../../objects/UserData';
import { DeleteModal } from '../../sharedComponents/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setOrganization } from '../../store/orgSlice';
import { selectUserData } from '../../store/userDataSlice';
import { deleteDescription, useArchiveOrg } from '../../utils/handleDelete';
export const SaveNew = ({ org, editMode, isSubmitting, }) => {
    const archiveEnabled = useFeatureFlag(FeatureFlags.FF_ARCHIVE_ORG);
    const buttonText = archiveEnabled
        ? 'Archive organization'
        : 'Delete organization';
    const handleArchiveOrg = useArchiveOrg();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserData);
    useEffect(() => { }, [editMode]);
    const { isOpen: isOpenOrgDelete, onOpen: onOpenOrgDelete, onClose: onCloseOrgDelete, } = useDisclosure();
    const onEnd = () => {
        onCloseOrgDelete();
        dispatch(setOrganization(undefined));
        navigate(Paths.HOME);
    };
    return (_jsxs(_Fragment, { children: [_jsx(HStack, { children: editMode && (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'submit', variant: variants.editBtn, w: 'none', isDisabled: isSubmitting ? true : false, _hover: { variant: variants.secDarkGrayBtn }, children: "Save changes" }), user &&
                            user.systemRole === SystemRoleEnum.SUPER_ADMIN &&
                            !window.location.href.includes(Paths.ORG_DETAILS_CREATE) && (_jsx(Button, { variant: variants.transDelBtn, onClick: () => {
                                onOpenOrgDelete();
                            }, children: buttonText }))] })) }), _jsx(DeleteModal, { isOpen: isOpenOrgDelete, onClose: onCloseOrgDelete, deleteItem: () => {
                    if (org) {
                        handleArchiveOrg(org, onEnd);
                    }
                }, title: archiveEnabled
                    ? 'Archive Organization'
                    : 'Delete Organization', description: archiveEnabled
                    ? deleteDescription((org === null || org === void 0 ? void 0 : org.name) || 'Organization', 'archive')
                    : deleteDescription((org === null || org === void 0 ? void 0 : org.name) || 'Organization'), buttonText: archiveEnabled ? 'Archive' : 'Delete' })] }));
};
