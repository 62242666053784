import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Td, Tr } from '@chakra-ui/react';
import { OrganizationRoleEnum, } from '../../../objects/UserData';
import { UserApps } from '../../../sharedComponents/UserApps';
import { toOrgRoleString } from '../../../utils/userRoleUtils';
export const UserOrganizationTableRow = ({ user, organizationRole, org, }) => {
    const allOrgFacilities = [];
    if (org && org.sites.length > 0) {
        org === null || org === void 0 ? void 0 : org.sites.forEach((site) => {
            if (site.facilities.length > 0) {
                site.facilities.forEach((facility) => allOrgFacilities.push(facility));
            }
        });
    }
    const userFacilities = allOrgFacilities.filter((facility) => {
        var _a;
        return (_a = user.facilityRoles) === null || _a === void 0 ? void 0 : _a.find((facRole) => facRole.facilityIdentity === facility.facilityIdentity);
    });
    const getFacilityRole = (facility) => {
        var _a, _b, _c;
        return ((_c = (_b = (_a = user.facilityRoles) === null || _a === void 0 ? void 0 : _a.find((facRole) => facRole.facilityIdentity === facility.facilityIdentity)) === null || _b === void 0 ? void 0 : _b.role) !== null && _c !== void 0 ? _c : '-');
    };
    return (_jsx(_Fragment, { children: userFacilities.length > 0 &&
            organizationRole.role !== OrganizationRoleEnum.LOCAL_ADMIN &&
            organizationRole.role !== OrganizationRoleEnum.CLIENT_CONCIERGE ? (userFacilities.map((facility) => (_jsxs(Tr, { h: '50px', boxSizing: 'border-box', fontSize: '14px', "data-testid": 'user-org-facility-row', children: [_jsx(Td, { py: 0, children: org === null || org === void 0 ? void 0 : org.name }), _jsx(Td, { py: 0, children: toOrgRoleString(organizationRole.role) }), _jsx(Td, { py: 0, children: facility.name }), _jsx(Td, { py: 0, children: getFacilityRole(facility) }), _jsx(Td, { children: _jsx(UserApps, { user: user, org: org }) })] }, facility.facilityIdentity)))) : (_jsxs(Tr, { h: '50px', boxSizing: 'border-box', fontSize: '14px', "data-testid": 'user-org-row', children: [_jsx(Td, { py: 0, children: org === null || org === void 0 ? void 0 : org.name }), _jsx(Td, { py: 0, children: toOrgRoleString(organizationRole.role) }), _jsx(Td, { py: 0, children: "-" }), _jsx(Td, { py: 0, children: "-" }), _jsx(Td, { children: _jsx(UserApps, { user: user, org: org }) })] })) }));
};
